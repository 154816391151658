import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject)
    return originalPush.call(this, location, onResolve, onReject).catch((err) => err)
  return originalPush.call(this, location).catch((err) => err)
}

// initial route
export const baseRoutes = [
  {
    path: '/login',
    name: 'rootLogin',
    redirect: {
      name: 'login'
    },
    component: () => import('@/views/Login'),
    children: [
      {
        path: 'form',
        name: 'login',
        component: () => import('@/views/Login/Login')
      },
      {
        path: 'landing',
        name: 'landing',
        component: () => import('@/views/Login/Landing'),
        beforeEnter: (to, from, next) => {
          const { code } = to.query
          if (!code || window.parent == window) {
            next({
              name: 'login'
            })
            return
          }
          next()
        }
      },
      {
        path: 'registerAccount',
        name: 'registerAccount',
        component: () => import('@/views/Login/RegisterAccount')
      },
      {
        path: 'forgetPsw',
        name: 'forgetPsw',
        component: () => import('@/views/Login/ForgetPsw')
      },
      {
        path: 'resetPsw',
        name: 'resetPsw',
        meta: {
          requireAuth: true
        },
        component: () => import('@/views/Login/ResetPsw')
      },
      {
        path: 'status',
        name: 'loginStatus',
        component: () => import('@/views/Login/ShowStatus')
      },
      {
        path: '*',
        name: 'any',
        redirect: {
          name: 'login'
        }
      }
    ]
  },
  {
    path: '/404',
    name: '404',
    component: () => import('../views/Error/404.vue')
  }
]

/**
 *  prepare dynamically loaded routes according to different user permissions
 */
export const DynamicRoutes = () => [
  {
    path: '',
    name: 'main',
    component: () => import('@/views/Layout'),
    children: []
  },
  {
    path: '*',
    redirect: '/404'
  }
]

const createRouter = () => {
  return new VueRouter({
    mode: 'history',
    routes: baseRoutes
  })
}

const router = createRouter()

// export function resetRouter() {
//     const newRouter = createRouter()
//     router.matcher = newRouter.matcher
// }

export const initialRouter = () => {
  const newRouter = createRouter()
  router.matcher = newRouter.matcher
}

export const freePermissionRoutes = router.getRoutes().filter((r) => r?.meta?.requireAuth !== true)

// const router = initialRouter()

export default router
