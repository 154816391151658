<template>
  <div class="checkbox-group">
    <van-checkbox
      v-for="item in columnsOptions"
      :key="item[options.value]"
      v-model="item.checked"
    >
      {{ item[options.label] }}
    </van-checkbox>
  </div>
</template>
<script>
export default {
  name: 'TheH5MultipleList',
  props: {
    columns: {
      type: Array,
      default() {
        return [
          // {
          //     code: '',
          //     label: '',
          // }
        ]
      }
    },
    options: {
      type: Object,
      default() {
        return {
          label: 'label', //文本
          value: 'value' //值
        }
      }
    },
    value: {
      type: Array,
      default() {
        return []
      }
    },
    show: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      columnsOptions: []
    }
  },
  watch: {
    show: {
      handler(newValue) {
        if (newValue) this.changeValue()
      },
      immediate: true
    }
  },
  methods: {
    getValues() {
      const { options } = this
      const values = this.columnsOptions.reduce((arr, cur) => {
        if (cur.checked) {
          arr.push(cur[options.value])
        }
        return arr
      }, [])
      return values
    },
    changeValue() {
      this.columnsOptions = JSON.parse(JSON.stringify(this.columns))
      const { options, columnsOptions, value } = this
      this.columnsOptions = columnsOptions.map((o) => {
        if (value.includes(o[options.value])) {
          return {
            ...o,
            checked: true
          }
        } else {
          return {
            ...o,
            checked: false
          }
        }
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.checkbox-group {
  width: 100%;
  max-height: 100%;
  overflow-y: auto;
  .van-checkbox {
    width: 100%;
    padding: 10px 0;
    box-sizing: border-box;
    border-bottom: 1px solid #e9edf7;

    &:last-child {
      border: none;
    }
  }
}
</style>
