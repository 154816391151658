import {
  PAY_OUT,
  PAY_IN,
  MARKET_PLACE_ORDERS,
  STATUS_INITIAL_STATUS,
  STATUS_IN_PROGRESS,
  STATUS_SETTLED,
  STATUS_FAILED,
  STATUS_CHARGED_BACK,
  STATUS_REFUNDING,
  STATUS_REFUNDED,
  STATUS_EXPIRED,
  STATUS_REJECTED,
  STATUS_CREATED,
  STATUS_CANCELLED,
  TRANSACTION_WO_STATUS_PENDING,
  TRANSACTION_WO_STATUS_PROCESSING,
  TRANSACTION_WO_STATUS_SOLVED
} from '@/utils/constants'
export default {
  namespaced: true,
  state: {},
  getters: {
    transactionsType(state, getters, rootState) {
      const { $t } = rootState.vm
      return {
        [PAY_IN]: $t(`common.${PAY_IN}`),
        [PAY_OUT]: $t(`common.${PAY_OUT}`),
        [MARKET_PLACE_ORDERS]: $t(`common.${MARKET_PLACE_ORDERS}`)
      }
    },
    statusIcons() {
      return {
        [STATUS_INITIAL_STATUS]: '#4395FF',
        [STATUS_IN_PROGRESS]: '#FFE600',
        [STATUS_SETTLED]: '#05E9B2',
        [STATUS_FAILED]: '#FF5353',
        [STATUS_CHARGED_BACK]: '#4162DF',
        [STATUS_REFUNDING]: '#FF9901',
        [STATUS_REFUNDED]: '#9AA2B7',
        [STATUS_EXPIRED]: '#D2D2D2',
        [STATUS_REJECTED]: '#333333',
        [STATUS_CREATED]: '#31CEFF',
        [STATUS_CANCELLED]: '#B48F65'
      }
    },
    transactionsCaseStatus(state, getters, rootState) {
      const { $t } = rootState.vm
      return {
        [TRANSACTION_WO_STATUS_PENDING]: {
          label: $t('transactionCase.status.pending'),
          icon: 'el-icon-s-order'
        },
        [TRANSACTION_WO_STATUS_PROCESSING]: {
          label: $t('transactionCase.status.processing'),
          icon: 'el-icon-s-comment'
        },
        [TRANSACTION_WO_STATUS_SOLVED]: {
          label: $t('transactionCase.status.solved'),
          icon: 'el-icon-s-claim'
        }
      }
    }
  },
  mutations: {},
  actions: {}
}
