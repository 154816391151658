export default [
  {
    code: '+86',
    en: 'China',
    cn: '中国',
    abbr3: 'CHN',
    abbr2: 'CN'
  },
  {
    code: '+93',
    en: 'Afghanistan',
    cn: '阿富汗',
    abbr3: 'AFG',
    abbr2: 'AF'
  },
  {
    code: '+355',
    en: 'Albania',
    cn: '阿尔巴尼亚',
    abbr3: 'ALB',
    abbr2: 'AL'
  },
  {
    code: '+213',
    en: 'Algeria',
    cn: '阿尔及利亚',
    abbr3: 'DZA',
    abbr2: 'DZ'
  },
  {
    code: '+376',
    en: 'Andorra',
    cn: '安道尔',
    abbr3: 'AND',
    abbr2: 'AD'
  },
  {
    code: '+244',
    en: 'Angola',
    cn: '安哥拉',
    abbr3: 'AGO',
    abbr2: 'AO'
  },
  {
    code: '+1264',
    en: 'Anguilla',
    cn: '安圭拉',
    abbr3: 'AIA',
    abbr2: 'AI'
  },
  {
    code: '+247',
    en: 'hahahahah',
    cn: '阿森松岛',
    abbr3: 'ASQ',
    abbr2: 'AC'
  },
  {
    code: '+1268',
    en: 'Antigua and Barbuda',
    cn: '安提瓜和巴布达',
    abbr3: 'ATG',
    abbr2: 'AG'
  },
  {
    code: '+54',
    en: 'Argentina',
    cn: '阿根廷',
    abbr3: 'ARG',
    abbr2: 'AR'
  },
  {
    code: '+374',
    en: 'Armenia',
    cn: '亚美尼亚',
    abbr3: 'ARM',
    abbr2: 'AM'
  },
  {
    code: '+297',
    en: 'Aruba',
    cn: '阿鲁巴',
    abbr3: 'ABW',
    abbr2: 'AW'
  },
  {
    code: '+61',
    en: 'Australia',
    cn: '澳大利亚',
    abbr3: 'AUS',
    abbr2: 'AU'
  },
  {
    code: '+43',
    en: 'Austria',
    cn: '奥地利',
    abbr3: 'AUT',
    abbr2: 'AT'
  },
  {
    code: '+994',
    en: 'Azerbaijan',
    cn: '阿塞拜疆',
    abbr3: 'AZE',
    abbr2: 'AZ'
  },
  {
    code: '+1242',
    en: 'Bahamas',
    cn: '巴哈马',
    abbr3: 'BHS',
    abbr2: 'BS'
  },
  {
    code: '+973',
    en: 'Bahrain',
    cn: '巴林',
    abbr3: 'BHR',
    abbr2: 'BH'
  },
  {
    code: '+880',
    en: 'Bangladesh',
    cn: '孟加拉国',
    abbr3: 'BGD',
    abbr2: 'BD'
  },
  {
    code: '+1246',
    en: 'Barbados',
    cn: '巴巴多斯',
    abbr3: 'BRB',
    abbr2: 'BB'
  },
  {
    code: '+375',
    en: 'Belarus',
    cn: '白俄罗斯',
    abbr3: 'BLR',
    abbr2: 'BY'
  },
  {
    code: '+32',
    en: 'Belgium',
    cn: '比利时',
    abbr3: 'BEL',
    abbr2: 'BE'
  },
  {
    code: '+501',
    en: 'Belize',
    cn: '伯利兹',
    abbr3: 'BLZ',
    abbr2: 'BZ'
  },
  {
    code: '+229',
    en: 'Benin',
    cn: '贝宁',
    abbr3: 'BEN',
    abbr2: 'BJ'
  },
  {
    code: '+1441',
    en: 'Bermuda',
    cn: '百慕大',
    abbr3: 'BMU',
    abbr2: 'BM'
  },
  {
    code: '+975',
    en: 'Bhutan',
    cn: '不丹',
    abbr3: 'BTN',
    abbr2: 'BT'
  },
  {
    code: '+591',
    en: 'Bolivia',
    cn: '玻利维亚',
    abbr3: 'BOL',
    abbr2: 'BO'
  },
  {
    code: '+387',
    en: 'Bosnia and Herzegovina',
    cn: '波斯尼亚和黑塞哥维那',
    abbr3: 'BIH',
    abbr2: 'BA'
  },
  {
    code: '+267',
    en: 'Botwana',
    cn: '博茨瓦纳',
    abbr3: 'BWA',
    abbr2: 'BW'
  },
  {
    code: '+55',
    en: 'Brazill',
    cn: '巴西',
    abbr3: 'BRA',
    abbr2: 'BR'
  },
  {
    code: '+673',
    en: 'Brunei',
    cn: '文莱',
    abbr3: 'BRN',
    abbr2: 'BN'
  },
  {
    code: '+359',
    en: 'Bulgaria',
    cn: '保加利亚',
    abbr3: 'BGR',
    abbr2: 'BG'
  },
  {
    code: '+226',
    en: 'Burkina Faso',
    cn: '布基纳法索',
    abbr3: 'BFA',
    abbr2: 'BF'
  },
  {
    code: '+257',
    en: 'Burundi',
    cn: '布隆迪',
    abbr3: 'BDI',
    abbr2: 'BI'
  },
  {
    code: '+855',
    en: 'Cambodia',
    cn: '柬埔寨',
    abbr3: 'KHM',
    abbr2: 'KH'
  },
  {
    code: '+237',
    en: 'Cameroon',
    cn: '喀麦隆',
    abbr3: 'CMR',
    abbr2: 'CM'
  },
  {
    code: '+1',
    en: 'Canada',
    cn: '加拿大',
    abbr3: 'CAN',
    abbr2: 'CA'
  },
  {
    code: '+238',
    en: 'Cape Verde',
    cn: '佛得角',
    abbr3: 'CPV',
    abbr2: 'CV'
  },
  {
    code: '+1345',
    en: 'Cayman Islands',
    cn: '开曼群岛',
    abbr3: 'CYM',
    abbr2: 'KY'
  },
  {
    code: '+236',
    en: 'Central African Republic',
    cn: '中非共和国',
    abbr3: 'CAF',
    abbr2: 'CF'
  },
  {
    code: '+235',
    en: 'Chad',
    cn: '乍得',
    abbr3: 'TCD',
    abbr2: 'TD'
  },
  {
    code: '+56',
    en: 'Chile',
    cn: '智利',
    abbr3: 'CHL',
    abbr2: 'CL'
  },
  {
    code: '+57',
    en: 'Colombia',
    cn: '哥伦比亚',
    abbr3: 'COL',
    abbr2: 'CO'
  },
  {
    code: '+269',
    en: 'Comoros',
    cn: '科摩罗',
    abbr3: 'COM',
    abbr2: 'KM'
  },
  {
    code: '+242',
    en: 'Republic of the Congo',
    cn: '刚果共和国',
    abbr3: 'COG',
    abbr2: 'CG'
  },
  {
    code: '+243',
    en: 'Democratic Republic of the Congo',
    cn: '刚果民主共和国',
    abbr3: 'COD',
    abbr2: 'CD'
  },
  {
    code: '+682',
    en: 'Cook Islands',
    cn: '库克群岛',
    abbr3: 'COK',
    abbr2: 'CK'
  },
  {
    code: '+506',
    en: 'Costa Rica',
    cn: '哥斯达黎加',
    abbr3: 'CRI',
    abbr2: 'CR'
  },
  {
    code: '+225',
    en: 'Cote divoire',
    cn: '科特迪沃',
    abbr3: 'CIV',
    abbr2: 'CI'
  },
  {
    code: '+385',
    en: 'Croatia',
    cn: '克罗地亚',
    abbr3: 'HRV',
    abbr2: 'HR'
  },
  {
    code: '+53',
    en: 'Cuba',
    cn: '古巴',
    abbr3: 'CUB',
    abbr2: 'CU'
  },
  {
    code: '+357',
    en: 'Cyprus',
    cn: '塞浦路斯',
    abbr3: 'CYP',
    abbr2: 'CY'
  },
  {
    code: '+420',
    en: 'Czech Republic',
    cn: '捷克共和国',
    abbr3: 'CZE',
    abbr2: 'CZ'
  },
  {
    code: '+45',
    en: 'Denmark',
    cn: '丹麦',
    abbr3: 'DNK',
    abbr2: 'DK'
  },
  {
    code: '+253',
    en: 'Djibouti',
    cn: '吉布提',
    abbr3: 'DJI',
    abbr2: 'DJ'
  },
  {
    code: '+1767',
    en: 'Dominica',
    cn: '多米尼加',
    abbr3: 'DMA',
    abbr2: 'DM'
  },
  {
    code: '+1809',
    en: 'Dominican Republic',
    cn: '多米尼加共和国',
    abbr3: 'DOM',
    abbr2: 'DO'
  },
  {
    code: '+593',
    en: 'Ecuador',
    cn: '厄瓜多尔',
    abbr3: 'ECU',
    abbr2: 'EC'
  },
  {
    code: '+20',
    en: 'Egypt',
    cn: '埃及',
    abbr3: 'EGY',
    abbr2: 'EG'
  },
  {
    code: '+503',
    en: 'EISalvador',
    cn: '萨尔瓦多',
    abbr3: 'SLV',
    abbr2: 'SV'
  },
  {
    code: '+372',
    en: 'Estonia',
    cn: '爱沙尼亚',
    abbr3: 'EST',
    abbr2: 'EE'
  },
  {
    code: '+251',
    en: 'Ethiopia',
    cn: '埃塞俄比亚',
    abbr3: 'ETH',
    abbr2: 'ET'
  },
  {
    code: '+298',
    en: 'Faroe Islands',
    cn: '法罗群岛',
    abbr3: 'FRO',
    abbr2: 'FO'
  },
  {
    code: '+679',
    en: 'Fiji',
    cn: '斐济',
    abbr3: 'FJI',
    abbr2: 'FJ'
  },
  {
    code: '+358',
    en: 'Finland',
    cn: '芬兰',
    abbr3: 'FIN',
    abbr2: 'FI'
  },
  {
    code: '+33',
    en: 'France',
    cn: '法国',
    abbr3: 'FRA',
    abbr2: 'FR'
  },
  {
    code: '+594',
    en: 'French Guiana',
    cn: '法属圭亚那',
    abbr3: 'GUF',
    abbr2: 'GF'
  },
  {
    code: '+689',
    en: 'French Polynesia',
    cn: '法属波利尼西亚',
    abbr3: 'PYF',
    abbr2: 'PF'
  },
  {
    code: '+241',
    en: 'Gabon',
    cn: '加蓬',
    abbr3: 'GAB',
    abbr2: 'GA'
  },
  {
    code: '+220',
    en: 'Gambia',
    cn: '冈比亚',
    abbr3: 'GMB',
    abbr2: 'GM'
  },
  {
    code: '+995',
    en: 'Georgia',
    cn: '格鲁吉亚',
    abbr3: 'GEO',
    abbr2: 'GE'
  },
  {
    code: '+94',
    en: 'Germany',
    cn: '德国',
    abbr3: 'DEU',
    abbr2: 'DE'
  },
  {
    code: '+233',
    en: 'Ghana',
    cn: '加纳',
    abbr3: 'GHA',
    abbr2: 'GH'
  },
  {
    code: '+350',
    en: 'Gibraltar',
    cn: '直布罗陀',
    abbr3: 'GIB',
    abbr2: 'GI'
  },
  {
    code: '+30',
    en: 'Greece',
    cn: '希腊',
    abbr3: 'GRC',
    abbr2: 'GR'
  },
  {
    code: '+299',
    en: 'Greenland',
    cn: '格陵兰',
    abbr3: 'GRL',
    abbr2: 'GL'
  },
  {
    code: '+1473',
    en: 'Grenada',
    cn: '格林纳达',
    abbr3: 'GRD',
    abbr2: 'GD'
  },
  {
    code: '+590',
    en: 'Guadeloupe',
    cn: '瓜德罗普',
    abbr3: 'GLP',
    abbr2: 'GP'
  },
  {
    code: '+1671',
    en: 'Guam',
    cn: '关岛',
    abbr3: 'GUM',
    abbr2: 'GU'
  },
  {
    code: '+502',
    en: 'Guatemala',
    cn: '危地马拉',
    abbr3: 'GTM',
    abbr2: 'GT'
  },
  {
    code: '+240',
    en: 'Guinea',
    cn: '几内亚',
    abbr3: 'GIN',
    abbr2: 'GN'
  },
  {
    code: '+44',
    en: 'Guernsey',
    cn: '根西',
    abbr3: 'GGY',
    abbr2: 'GG'
  },
  {
    code: '+224',
    en: 'Guinea',
    cn: '几内亚',
    abbr3: 'GIN1',
    abbr2: 'GN'
  },
  {
    code: '+592',
    en: 'Guyana',
    cn: '圭亚那',
    abbr3: 'GUY',
    abbr2: 'GY'
  },
  {
    code: '+509',
    en: 'Haiti',
    cn: '海地',
    abbr3: 'HTI',
    abbr2: 'HT'
  },
  {
    code: '+504',
    en: 'Honduras',
    cn: '洪都拉斯',
    abbr3: 'HND',
    abbr2: 'HN'
  },
  {
    code: '+852',
    en: 'Hong Kong',
    cn: '香港',
    abbr3: 'HKG',
    abbr2: 'HK'
  },
  {
    code: '+95',
    en: 'Myanmar',
    cn: '缅甸',
    abbr3: 'MMR',
    abbr2: 'MM'
  },
  {
    code: '+36',
    en: 'Hungary',
    cn: '匈牙利',
    abbr3: 'HUN',
    abbr2: 'HU'
  },
  {
    code: '+354',
    en: 'Iceland',
    cn: '冰岛',
    abbr3: 'ISL',
    abbr2: 'IS'
  },
  {
    code: '+91',
    en: 'Indea',
    cn: '印度',
    abbr3: 'IND',
    abbr2: 'IN'
  },
  {
    code: '+62',
    en: 'Indonesia',
    cn: '印度尼西亚',
    abbr3: 'IDN',
    abbr2: 'ID'
  },
  {
    code: '+98',
    en: 'Iran',
    cn: '伊朗',
    abbr3: 'IRN',
    abbr2: 'IR'
  },
  {
    code: '+964',
    en: 'Iraq',
    cn: '伊拉克',
    abbr3: 'IRQ',
    abbr2: 'IQ'
  },
  {
    code: '+353',
    en: 'Ireland',
    cn: '爱尔兰',
    abbr3: 'IRL',
    abbr2: 'IE'
  },
  {
    code: '+44',
    en: 'Isle of Man',
    cn: '马恩岛',
    abbr3: 'IMN',
    abbr2: 'IM'
  },
  {
    code: '+972',
    en: 'Israel',
    cn: '以色列',
    abbr3: 'ISR',
    abbr2: 'IL'
  },
  {
    code: '+93',
    en: 'Italy',
    cn: '意大利',
    abbr3: 'ITA',
    abbr2: 'IT'
  },
  {
    code: '+1876',
    en: 'Jamaica',
    cn: '牙买加',
    abbr3: 'JAM',
    abbr2: 'JM'
  },
  {
    code: '+81',
    en: 'Japan',
    cn: '日本',
    abbr3: 'JPN',
    abbr2: 'JP'
  },
  {
    code: '+44',
    en: 'Jersey',
    cn: '泽西岛',
    abbr3: 'JEY',
    abbr2: 'JE'
  },
  {
    code: '+962',
    en: 'Jordan',
    cn: '约旦',
    abbr3: 'JOR',
    abbr2: 'JO'
  },
  {
    code: '+7',
    en: 'Kazeakhstan',
    cn: '哈萨克斯坦',
    abbr3: 'KAZ',
    abbr2: 'KZ'
  },
  {
    code: '+254',
    en: 'Kenya',
    cn: '肯尼亚',
    abbr3: 'KEN',
    abbr2: 'KE'
  },
  {
    code: '+383',
    en: 'Kosovo',
    cn: '科索沃',
    abbr3: 'XKX',
    abbr2: 'XK'
  },
  {
    code: '+965',
    en: 'Kuwait',
    cn: '科威特',
    abbr3: 'KWT',
    abbr2: 'KW'
  },
  {
    code: '+996',
    en: 'Kyrgyzstan',
    cn: '吉尔吉斯斯坦',
    abbr3: 'KGZ',
    abbr2: 'KG'
  },
  {
    code: '+856',
    en: 'Laos',
    cn: '老挝',
    abbr3: 'LAO',
    abbr2: 'LA'
  },
  {
    code: '+371',
    en: 'Latvia',
    cn: '拉脱维亚',
    abbr3: 'LVA',
    abbr2: 'LV'
  },
  {
    code: '+961',
    en: 'Lebanon',
    cn: '黎巴嫩',
    abbr3: 'LBN',
    abbr2: 'LB'
  },
  {
    code: '+266',
    en: 'Lesotho',
    cn: '莱索托',
    abbr3: 'LSO',
    abbr2: 'LS'
  },
  {
    code: '+231',
    en: 'Liberia',
    cn: '利比里亚',
    abbr3: 'LBR',
    abbr2: 'LR'
  },
  {
    code: '+218',
    en: 'Libya',
    cn: '利比亚',
    abbr3: 'LBY',
    abbr2: 'LY'
  },
  {
    code: '+423',
    en: 'Liechtenstein',
    cn: '列支敦士登',
    abbr3: 'LIE',
    abbr2: 'LI'
  },
  {
    code: '+370',
    en: 'Lithuania',
    cn: '立陶宛',
    abbr3: 'LTU',
    abbr2: 'LT'
  },
  {
    code: '+352',
    en: 'Luxembourg',
    cn: '卢森堡',
    abbr3: 'LUX',
    abbr2: 'LU'
  },
  {
    code: '+853',
    en: 'Macao',
    cn: '澳门',
    abbr3: 'MAC',
    abbr2: 'MO'
  },
  {
    code: '+389',
    en: 'Macedonia',
    cn: '马其顿',
    abbr3: 'MKD',
    abbr2: 'MK'
  },
  {
    code: '+261',
    en: 'Madagascar',
    cn: '马达加斯加',
    abbr3: 'MDG',
    abbr2: 'MG'
  },
  {
    code: '+265',
    en: 'Malawi',
    cn: '马拉维',
    abbr3: 'MWI',
    abbr2: 'MW'
  },
  {
    code: '+60',
    en: 'Malaysia',
    cn: '马来西亚',
    abbr3: 'MYS',
    abbr2: 'MY'
  },
  {
    code: '+960',
    en: 'Maldives',
    cn: '马尔代夫',
    abbr3: 'MDV',
    abbr2: 'MV'
  },
  {
    code: '+223',
    en: 'Mali',
    cn: '马里',
    abbr3: 'MLI',
    abbr2: 'ML'
  },
  {
    code: '+356',
    en: 'Malta',
    cn: '马耳他',
    abbr3: 'MLT',
    abbr2: 'MT'
  },
  {
    code: '+596',
    en: 'Martinique',
    cn: '马提尼克'
  },
  {
    code: '+222',
    en: 'Mauritania',
    cn: '毛里塔尼亚',
    abbr3: 'MRT',
    abbr2: 'MR'
  },
  {
    code: '+230',
    en: 'Mauritius',
    cn: '毛里求斯',
    abbr3: 'MUS',
    abbr2: 'MU'
  },
  {
    code: '+262',
    en: 'Mayotte',
    cn: '马约特',
    abbr3: 'MYT',
    abbr2: 'YT'
  },
  {
    code: '+52',
    en: 'Mexico',
    cn: '墨西哥',
    abbr3: 'MEX',
    abbr2: 'MX'
  },
  {
    code: '+373',
    en: 'Moldova',
    cn: '摩尔多瓦',
    abbr3: 'MDA',
    abbr2: 'MD'
  },
  {
    code: '+377',
    en: 'Monaco',
    cn: '摩纳哥',
    abbr3: 'MCO',
    abbr2: 'MC'
  },
  {
    code: '+976',
    en: 'Mongolia',
    cn: '蒙古',
    abbr3: 'MNG',
    abbr2: 'MN'
  },
  {
    code: '+382',
    en: 'Montenegro',
    cn: '黑山',
    abbr3: 'MNE',
    abbr2: 'ME'
  },
  {
    code: '+1664',
    en: 'Montserrat',
    cn: '蒙特塞拉特',
    abbr3: 'MSR',
    abbr2: 'MS'
  },
  {
    code: '+212',
    en: 'Morocco',
    cn: '摩洛哥',
    abbr3: 'MAR',
    abbr2: 'MA'
  },
  {
    code: '+258',
    en: 'Mozambique',
    cn: '莫桑比克',
    abbr3: 'MOZ',
    abbr2: 'MZ'
  },
  {
    code: '+264',
    en: 'Namibia',
    cn: '纳米比亚',
    abbr3: 'NAM',
    abbr2: 'NA'
  },
  {
    code: '+977',
    en: 'Nepal',
    cn: '尼泊尔',
    abbr3: 'NPL',
    abbr2: 'NP'
  },
  {
    code: '+31',
    en: 'Netherlands',
    cn: '荷兰',
    abbr3: 'NLD',
    abbr2: 'NL'
  },
  {
    code: '+599',
    en: 'Netherlands Antillse',
    cn: '荷属安的列斯',
    abbr3: 'ANT',
    abbr2: 'AN'
  },
  {
    code: '+687',
    en: 'New Caledonia',
    cn: '新喀里多尼亚',
    abbr3: 'NCL',
    abbr2: 'NC'
  },
  {
    code: '+64',
    en: 'NewZealand',
    cn: '新西兰',
    abbr3: 'NZL',
    abbr2: 'NZ'
  },
  {
    code: '+505',
    en: 'Nicaragua',
    cn: '尼加拉瓜',
    abbr3: 'NIC',
    abbr2: 'NI'
  },
  {
    code: '+227',
    en: 'Niger',
    cn: '尼日尔',
    abbr3: 'NER',
    abbr2: 'NE'
  },
  {
    code: '+234',
    en: 'Nigeria',
    cn: '尼日利亚',
    abbr3: 'NGA',
    abbr2: 'NG'
  },
  {
    code: '+47',
    en: 'Norway',
    cn: '挪威',
    abbr3: 'NOR',
    abbr2: 'NO'
  },
  {
    code: '+968',
    en: 'Oman',
    cn: '阿曼',
    abbr3: 'OMN',
    abbr2: 'OM'
  },
  {
    code: '+92',
    en: 'Pakistan',
    cn: '巴基斯坦',
    abbr3: 'PAK',
    abbr2: 'PK'
  },
  {
    code: '+970',
    en: 'Palestinian',
    cn: '巴勒斯坦',
    abbr3: 'PSE',
    abbr2: 'PS'
  },
  {
    code: '+507',
    en: 'Panama',
    cn: '巴拿马',
    abbr3: 'PAN',
    abbr2: 'PA'
  },
  {
    code: '+675',
    en: 'Papua New Guinea',
    cn: '巴布亚新几内亚',
    abbr3: 'PNG',
    abbr2: 'PG'
  },
  {
    code: '+595',
    en: 'Paraguay',
    cn: '巴拉圭',
    abbr3: 'PRY',
    abbr2: 'PY'
  },
  {
    code: '+51',
    en: 'Peru',
    cn: '秘鲁',
    abbr3: 'PER',
    abbr2: 'PE'
  },
  {
    code: '+63',
    en: 'Philippines',
    cn: '菲律宾',
    abbr3: 'PHL',
    abbr2: 'PH'
  },
  {
    code: '+48',
    en: 'Poland',
    cn: '波兰',
    abbr3: 'POL',
    abbr2: 'PL'
  },
  {
    code: '+351',
    en: 'Portugal',
    cn: '葡萄牙',
    abbr3: 'PRT',
    abbr2: 'PT'
  },
  {
    code: '+1',
    en: 'PuertoRico',
    cn: '波多黎各',
    abbr3: 'PUR',
    abbr2: 'PR'
  },
  {
    code: '+974',
    en: 'Qatar',
    cn: '卡塔尔',
    abbr3: 'QAT',
    abbr2: 'QA'
  },
  {
    code: '+262',
    en: 'Reunion',
    cn: '留尼汪',
    abbr3: 'REU',
    abbr2: 'RE'
  },
  {
    code: '+40',
    en: 'Romania',
    cn: '罗马尼亚',
    abbr3: 'ROU',
    abbr2: 'RO'
  },
  {
    code: '+7',
    en: 'Russia',
    cn: '俄罗斯',
    abbr3: 'RUS',
    abbr2: 'RU'
  },
  {
    code: '+250',
    en: 'Rwanda',
    cn: '卢旺达',
    abbr3: 'RWA',
    abbr2: 'RW'
  },
  {
    code: '+684',
    en: 'Samoa Eastern',
    cn: '萨摩亚东部',
    abbr3: 'AAU',
    abbr2: 'AS'
  },
  {
    code: '+685',
    en: 'Samoa Western',
    cn: '萨摩亚西部',
    abbr3: 'WSM',
    abbr2: 'WS'
  },
  {
    code: '+378',
    en: 'San Marino',
    cn: '圣马力诺',
    abbr3: 'SMR',
    abbr2: 'SM'
  },
  {
    code: '+239',
    en: 'Sao Tome and Principe',
    cn: '圣多美和普林西比',
    abbr3: 'STP',
    abbr2: 'ST'
  },
  {
    code: '+966',
    en: 'Saudi Arabia',
    cn: '沙特阿拉伯',
    abbr3: 'SAU',
    abbr2: 'SA'
  },
  {
    code: '+221',
    en: 'Senegal',
    cn: '塞内加尔',
    abbr3: 'SEN',
    abbr2: 'SN'
  },
  {
    code: '+381',
    en: 'Serbia',
    cn: '塞尔维亚',
    abbr3: 'SRB',
    abbr2: 'RS'
  },
  {
    code: '+248',
    en: 'Seychelles',
    cn: '塞舌尔',
    abbr3: 'SYC',
    abbr2: 'SC'
  },
  {
    code: '+232',
    en: 'Sierra Leone',
    cn: '塞拉利昂',
    abbr3: 'SLE',
    abbr2: 'SL'
  },
  {
    code: '+65',
    en: 'Singapore',
    cn: '新加坡',
    abbr3: 'SGP',
    abbr2: 'SG'
  },
  {
    code: '+421',
    en: 'Slovakia',
    cn: '斯洛伐克',
    abbr3: 'SVK',
    abbr2: 'SK'
  },
  {
    code: '+386',
    en: 'Slovenia',
    cn: '斯洛文尼亚',
    abbr3: 'SVN',
    abbr2: 'SI'
  },
  {
    code: '+27',
    en: 'South Africa',
    cn: '南非',
    abbr3: 'ZAF',
    abbr2: 'ZA'
  },
  {
    code: '+82',
    en: 'Korea',
    cn: '韩国',
    abbr3: 'KOR',
    abbr2: 'KR'
  },
  {
    code: '+34',
    en: 'Spain',
    cn: '西班牙',
    abbr3: 'ESP',
    abbr2: 'ES'
  },
  {
    code: '+94',
    en: 'SriLanka',
    cn: '斯里兰卡',
    abbr3: 'LKA',
    abbr2: 'LK'
  },
  {
    code: '+1869',
    en: 'St Kitts and Nevis',
    cn: '圣基茨和尼维斯',
    abbr3: 'KNA',
    abbr2: 'KN'
  },
  {
    code: '+1758',
    en: 'St.Lucia',
    cn: '圣卢西亚',
    abbr3: 'LCA',
    abbr2: 'LC'
  },
  {
    code: '+1784',
    en: 'St.Vincent',
    cn: '圣文森特',
    abbr3: 'VCT',
    abbr2: 'VC'
  },
  {
    code: '+249',
    en: 'Sudan',
    cn: '苏丹',
    abbr3: 'SDN',
    abbr2: 'SD'
  },
  {
    code: '+597',
    en: 'Suriname',
    cn: '苏里南',
    abbr3: 'SUR',
    abbr2: 'SR'
  },
  {
    code: '+268',
    en: 'Swaziland',
    cn: '斯威士兰',
    abbr3: 'SWZ',
    abbr2: 'SZ'
  },
  {
    code: '+46',
    en: 'Sweden',
    cn: '瑞典',
    abbr3: 'SWE',
    abbr2: 'SE'
  },
  {
    code: '+41',
    en: 'Switzerland',
    cn: '瑞士',
    abbr3: 'CHE',
    abbr2: 'CH'
  },
  {
    code: '+963',
    en: 'Syria',
    cn: '叙利亚',
    abbr3: 'SYR',
    abbr2: 'SY'
  },
  {
    code: '+886',
    en: 'Taiwan',
    cn: '台湾',
    abbr3: 'TWN',
    abbr2: 'TW'
  },
  {
    code: '+992',
    en: 'Tajikistan',
    cn: '塔吉克斯坦',
    abbr3: 'TJK',
    abbr2: 'TJ'
  },
  {
    code: '+255',
    en: 'Tanzania',
    cn: '坦桑尼亚',
    abbr3: 'TZA',
    abbr2: 'TZ'
  },
  {
    code: '+66',
    en: 'Thailand',
    cn: '泰国',
    abbr3: 'THA',
    abbr2: 'TH'
  },
  {
    code: '+670',
    en: 'Timor Leste',
    cn: '东帝汶',
    abbr3: 'TLS',
    abbr2: 'TL'
  },
  {
    code: '+228',
    en: 'Togo',
    cn: '多哥',
    abbr3: 'TGO',
    abbr2: 'TG'
  },
  {
    code: '+676',
    en: 'Tonga',
    cn: '汤加',
    abbr3: 'TON',
    abbr2: 'TO'
  },
  {
    code: '+1868',
    en: 'Trinidad and Tobago',
    cn: '特立尼达和多巴哥',
    abbr3: 'TTO',
    abbr2: 'TT'
  },
  {
    code: '+216',
    en: 'Tunisia',
    cn: '突尼斯',
    abbr3: 'TUN',
    abbr2: 'TN'
  },
  {
    code: '+90',
    en: 'Turkey',
    cn: '土耳其',
    abbr3: 'TUR',
    abbr2: 'TR'
  },
  {
    code: '+993',
    en: 'Turkmenistan',
    cn: '土库曼斯坦',
    abbr3: 'TKM',
    abbr2: 'TM'
  },
  {
    code: '+1649',
    en: 'Turks and Caicos Islands',
    cn: '特克斯和凯科斯群岛',
    abbr3: 'TCA',
    abbr2: 'TC'
  },
  {
    code: '+256',
    en: 'Uganda',
    cn: '乌干达',
    abbr3: 'UGA',
    abbr2: 'UG'
  },
  {
    code: '+380',
    en: 'Ukraine',
    cn: '乌克兰',
    abbr3: 'UKR',
    abbr2: 'UA'
  },
  {
    code: '+971',
    en: 'United Arab Emirates',
    cn: '阿拉伯联合酋长国',
    abbr3: 'ARE',
    abbr2: 'AE'
  },
  {
    code: '+44',
    en: 'United Kingdom',
    cn: '英国',
    abbr3: 'GBR',
    abbr2: 'GB'
  },
  {
    code: '+1',
    en: 'United States',
    cn: '美国',
    abbr3: 'USA',
    abbr2: 'US'
  },
  {
    code: '+598',
    en: 'Uruguay',
    cn: '乌拉圭',
    abbr3: 'URY',
    abbr2: 'UY'
  },
  {
    code: '+998',
    en: 'Uzbekistan',
    cn: '乌兹别克斯坦',
    abbr3: 'UZB',
    abbr2: 'UZ'
  },
  {
    code: '+678',
    en: 'Vanuatu',
    cn: '瓦努阿图',
    abbr3: 'VUT',
    abbr2: 'VU'
  },
  {
    code: '+58',
    en: 'Venezuela',
    cn: '委内瑞拉',
    abbr3: 'VEN',
    abbr2: 'VE'
  },
  {
    code: '+84',
    en: 'Vietnam',
    cn: '越南',
    abbr3: 'VNM',
    abbr2: 'VN'
  },
  {
    code: '+1340',
    en: 'Virgin Islands',
    cn: '维尔京群岛',
    abbr3: 'BVI',
    abbr2: 'VI'
  },
  {
    code: '+967',
    en: 'Yemen',
    cn: '也门',
    abbr3: 'YEM',
    abbr2: 'YE'
  },
  {
    code: '+260',
    en: 'Zambia',
    cn: '赞比亚',
    abbr3: 'ZMB',
    abbr2: 'ZM'
  },
  {
    code: '+263',
    en: 'Zimbabwe',
    cn: '津巴布韦',
    abbr3: 'ZWE',
    abbr2: 'ZW'
  }
]
