import store from '@/store'

export const FORM_ITEM_TYPES = {
  INPUT: 'el-input',
  SELECT: 'el-select',
  SELECT_OPTION: 'el-option',
  DATE_PICKER: 'el-date-picker'
}

export function createInputItem(name, label, componentProps) {
  const defComponentProps = {
    placeholder: store.state.vm.$t('common.errorTip'),
    clearable: true
  }
  return {
    name,
    label,
    component: {
      name: FORM_ITEM_TYPES.INPUT,
      props: { ...defComponentProps, ...componentProps }
    }
  }
}

export function createDatePickerItem(name, label, type, componentProps) {
  const { $t } = store.state.vm
  const defComponentProps = {
    type,
    rangeSeparator: '-',
    startPlaceholder: $t('search.datePlaceholder1'),
    endPlaceholder: $t('search.datePlaceholder2'),
    clearable: true
  }
  if (type === 'daterange' || type === 'datetime') {
    defComponentProps.valueFormat = 'yyyy-MM-dd'
  }
  return {
    name,
    label,
    type,
    component: {
      name: FORM_ITEM_TYPES.DATE_PICKER,
      props: { ...defComponentProps, ...componentProps }
    }
  }
}

export function createSelectItem(name, label, componentProps, options) {
  const { $t } = store.state.vm
  const defComponentProps = {
    placeholder: $t('search.selectPlaceholder'),
    noDataText: $t('common.noData'),
    clearable: true
  }
  return {
    name,
    label,
    component: {
      name: FORM_ITEM_TYPES.SELECT,
      props: { ...defComponentProps, ...componentProps },
      children: (options || []).map((o) => ({
        name: FORM_ITEM_TYPES.SELECT_OPTION,
        props: o,
        key: o.key || o.value
      }))
    }
  }
}
