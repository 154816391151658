<template>
  <TreeItem
    :columns="columns"
    :list="columns"
    :options="options"
    :show="show"
  />
</template>
<script>
import TreeItem from './TreeItem'
export default {
  name: 'H5TreeSelector',
  components: { TreeItem },
  props: {
    columns: {
      type: Array,
      default() {
        return [
          // {
          //     value: '',
          //     label: '',
          //     children: '',
          // }
        ]
      }
    },
    options: {
      type: Object,
      default() {
        return {
          label: 'label',
          value: 'value',
          children: 'children',
          parentValue: 'parentValue'
        }
      }
    },
    value: {
      type: Array,
      default() {
        return []
      }
    },
    show: {
      //is true: init selection
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      newValues: []
    }
  },
  watch: {
    show: {
      handler(newValue) {
        if (newValue) {
          this.setDefaultValues(this.columns)
        }
      },
      immediate: true
    }
  },
  methods: {
    getValues() {
      const { columns } = this
      this.newValues = []
      this.columnsSearch(columns)
      return this.newValues
    },
    columnsSearch(list) {
      const { options } = this
      list.forEach((el) => {
        if (el.checked) {
          this.newValues.push(el[options.value])
        }
        if (el[options.children] && el[options.children].length) {
          this.columnsSearch(el[options.children])
        }
      })
    },
    setDefaultValues(list) {
      const { options, value, setDefaultValues } = this
      list.forEach((el) => {
        if (value.includes(el[options.value])) {
          el.checked = true
        } else {
          el.checked = false
        }
        if (el[options.children] && el[options.children].length) {
          setDefaultValues(el[options.children])
        }
      })
    }
  }
}
</script>
<style lang="scss" scoped></style>
