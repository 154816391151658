import dayJs from 'dayjs'

export default {
  namespaced: true,
  state: {
    // system timezone offset
    get offset() {
      return -(new Date().getTimezoneOffset() / 60)
    },
    set offset(v) {}
  },
  getters: {
    timezone(state, getters, rootState, rootGetters) {
      const { timezone } = rootGetters
      return timezone
    },

    getDay:
      (state, getters) =>
      (t = new Date()) => {
        const { offset } = state
        const { timezone } = getters
        const diff = offset - timezone
        t.setHours(t.getHours() - diff)
        return dayJs(t)
      },

    // Fotmat the utc time obtained from back end to user's time
    utc2User:
      (state, getters) =>
      (time, format = 'YYYY/MM/DD HH:mm:ss') => {
        const { timezone } = getters
        let n = Math.abs(timezone)
        let t = timezone < 0 ? dayJs(time).subtract(n, 'hour') : dayJs(time).add(n, 'hour')
        return t.format(format)
      },

    // For date pick
    // The value of selected time remains unchanged， Change selected time's timezone to user's timezone
    sys2User: (state, getters) => (time, format) => {
      const { offset } = state
      const { timezone } = getters
      const diff = offset - timezone
      let t = dayJs(time).utcOffset(timezone)
      t = diff < 0 ? t.subtract(diff, 'hour') : t.add(diff, 'hour')
      return format ? t.format(format) : t
    },

    // Fotmat Time stamp to user time zone
    stamp2User:
      (state, getters) =>
      (time, format = 'YYYY-MM-DD HH:mm:ss') => {
        const { timezone } = getters
        let t = dayJs(time).utcOffset(timezone)
        return format ? t.format(format) : t
      },

    // Format time to utc time with user's timezone
    user2Utc:
      (state, getters) =>
      (time, format = 'YYYY-MM-DD HH:mm:ss') => {
        const { timezone } = getters
        let n = Math.abs(timezone)
        let t = timezone < 0 ? dayJs(time).add(n, 'hour') : dayJs(time).subtract(n, 'hour')
        return t.format(format)
      },

    // now one week
    defaultRange:
      (state, getters) =>
      (format = 'YYYY-MM-DD HH:mm:ss', type = 'day', num = 180) => {
        const today = getters.getDay()
        let start = today.subtract(num, type)
        return [start.format(format), today.format(format)]
      },

    // same defaultRange function, except end date added 100 years
    defaultRangeOfEndDateAdded100Years:
      (state, getters) =>
      (format = 'YYYY-MM-DD HH:mm:ss', type = 'day', num = 180) => {
        const { getDay } = getters
        let start = getDay().subtract(num, type)
        let end = getDay().add(100, 'year')
        return [start.format(format), end.format(format)]
      }
  }
}
