/**
 * @desc handle routing permission matching
 */

import { TERMINAL_WEB } from './constants'

export let menus = []

export function clearMenus() {
  menus = []
}

/**
 * @function recursionRouter
 * @desc Recursive matching route
 * @param {Array} userRouter user permission from back end
 * @param {Array} allRouter route from front end
 * @return {Array} matched route
 */
export function recursionRouter(userRouter = [], allRouter = [], btns = []) {
  const realRoutes = []
  const allBtns = btns
  allRouter.forEach((item) => {
    const { meta } = item
    if (meta.hide) {
      // routes about detail, etc
      realRoutes.push(item)
    } else {
      userRouter.some((i) => {
        // match by route field
        if (i.route.replace('/web/', '/') === meta.route) {
          meta.title = i.name
          if (Array.isArray(i.child) && i.child.length) {
            // match different types of permissions
            let routes = []
            let btns = allBtns
            let links = []
            i.child.forEach((r) => {
              const { type } = r
              switch (type) {
                case 'MENU':
                  if (!Array.isArray(r.child) || !r.child.length) {
                    delete r.child
                  }
                  routes.push(r)
                  break
                case 'BUTTON':
                  delete r.child
                  btns.push(r.permission)
                  break
                case 'LINK':
                  delete r.child
                  links.push(r.permission)
                  break
                default:
              }
            })
            if (routes.length) {
              item.children = recursionRouter(routes, item.children, btns)[0]
            } else {
              menus.push(meta.route)
            }
            item.meta = {
              // btns,
              links,
              ...meta
            }
          } else {
            menus.push(meta.route)
            delete i.child
          }
          if (i.terminal === TERMINAL_WEB) realRoutes.push(item)
          return true
        }
      })
    }
  })
  // console.log('realRoutes', realRoutes)
  return [realRoutes, allBtns]
}

/**
 * @function setDefaultRoute
 * @desc set parent route default redirect
 * @param {Array} routes matched route
 * @return {Array}
 */
export function setDefaultRoute(routes, arr = []) {
  routes.forEach((item) => {
    if (Array.isArray(item.children) && item.children.length) {
      item.redirect = item.children[0].meta.route
      setDefaultRoute(item.children, arr)
    } else {
      arr.push(item.meta.route)
    }
  })
  return arr
}
