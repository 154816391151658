<template>
  <van-collapse
    v-model="activeNames"
    accordion
  >
    <van-collapse-item
      v-for="item in tempCloumns"
      :key="item[options.value]"
      :name="item[options.value]"
      :is-link="item[options.children] && item[options.children].length ? true : false"
    >
      <template #title>
        <van-checkbox
          :key="item[options.value]"
          v-model="item.checked"
          label-disabled
          @click.stop.native="changeCheck(item, item.checked)"
        />
        {{ item[options.label] }}
      </template>
      <TreeItem
        v-if="item[options.children] && item[options.children].length"
        :key="item[options.value]"
        :columns="item[options.children]"
        :list="list"
        :options="options"
      />
    </van-collapse-item>
  </van-collapse>
</template>
<script>
import TreeItem from './TreeItem'
export default {
  name: 'TreeItem',
  components: { TreeItem },
  props: {
    columns: {
      type: Array,
      default() {
        return [
          // {
          //     value: '',
          //     label: '',
          //     children: '',
          // }
        ]
      }
    },
    list: {
      type: Array,
      default() {
        return [
          //all tree
          // {
          //     value: '',
          //     label: '',
          //     children: '',
          // }
        ]
      }
    },
    options: {
      type: Object,
      default() {
        return {
          label: 'label',
          value: 'value',
          children: 'children',
          parentValue: 'parentValue'
        }
      }
    },
    show: {
      //is true: init selection
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      activeNames: [],
      tempCloumns: []
    }
  },
  watch: {
    show: {
      handler(newValue) {
        if (newValue) {
          this.activeNames = []
        }
      },
      immediate: true
    }
  },
  created() {
    this.tempCloumns = this.columns
  },
  methods: {
    changeCheck(item, checked) {
      this.setChildren(item, checked)
      this.list.forEach((el) => {
        this.setParent(item, el, checked)
      })
    },
    setChildren(item, checked) {
      const flag = checked
      const { options, setChildren, $set } = this
      if (item[options.children] && item[options.children].length) {
        item[options.children].forEach((element) => {
          // element.checked = flag
          $set(element, 'checked', !flag)
          setChildren(element, flag)
        })
      }
    },
    setParent(item, items, checked) {
      if (!checked) {
        const { options, setParent, $set } = this
        if (items[options.value] == item[options.parentValue]) {
          $set(items, 'checked', true)
          if (items[options.parentValue] != '0') {
            this.list.forEach((els) => {
              setParent(items, els, checked)
            })
          }
        } else if (items[options.children] && items[options.children].length) {
          items[options.children].forEach((els) => {
            setParent(item, els, checked)
          })
        }
      }
    }
  }
}
</script>
<style lang="scss" scoped>
::v-deep .van-collapse-item {
  .van-cell__title {
    width: 100%;
    text-align: left;
    color: $h5ThemeFontColor;
    font-size: 14px;
    font-weight: 400;
    display: flex;
    align-items: center;
    .van-checkbox {
      margin-right: 6px;
    }
  }
}
</style>
