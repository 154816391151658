import { SET_MERCHANT } from '@/utils/constants'
import { merchantQueryList } from '@/api'
export default {
  namespaced: true,
  state: {
    merchantOptions: []
  },
  getters: {
    ownerOptions() {
      return {
        APAC: 'APAC',
        SSL: 'SSL',
        BR: 'BR',
        US: 'US',
        IT: 'IT',
        TBD: 'TBD'
      }
    }
  },
  mutations: {
    [SET_MERCHANT](state, val) {
      state.merchantOptions = val
    }
  },
  actions: {
    fetchMerchant({ commit }) {
      merchantQueryList().then((res) => {
        const list = res.data ? res.data.sort((a, b) => a.code.localeCompare(b.code)) : []
        commit(SET_MERCHANT, list)
      })
    }
  }
}
