export default {
  summary: {
    filterName1: 'Merchant',
    filterName2: 'Date',
    filterName3: 'Comparison',
    latitude1: 'By Merchant',
    latitude2: 'By Payment Method',
    latitude3: 'By Vendor',
    countLabel1: 'Total Amount(USD)',
    countLabel2: 'Total Count',
    tableColumn1: 'Payment Method',
    tableColumn2: 'Type',
    tableColumn3: 'Country',
    tableColumn4: 'Amount(Original Currency)',
    tableColumn5: 'Amount(USD)',
    tableColumn6: 'Comparison:',
    tableColumn7: 'Count',
    tableColumn8: 'Merchant',
    vendor: '@:common.vendor'
  },
  revenue: {
    merchant: 'Merchant',
    allMerchant: 'All Merchant',
    tableColumn1: 'Amount LC',
    tableColumn2: 'FX Rate',
    tableColumn3: 'Amount $',
    tableColumn4: 'Count',
    tableColumn5: 'Fee Income $',
    tableColumn6: 'Tax Income $',
    tableColumn7: 'FX Income $',
    tableColumn8: 'FX Income %',
    tableColumn9: 'Fee Cost $',
    tableColumn10: 'Tax Cost $',
    tableColumn11: 'FX Cost $',
    tableColumn12: 'Gross Revenue $',
    tableColumn13: 'Gross Revenue %',
    tableColumn14: 'Net Revenue $',
    tableColumn15: 'Net Revenue %',
    tableColumn16: 'Extra Income $',
    tableColumn17: 'Adjustment Income $',
    tableColumn18: 'Exchange Income $',
    comparisonType1: 'Gross Revenue',
    comparisonType2: 'Net Revenue'
  },
  transactionHistory: {
    title: 'Transaction History',
    merchantCode: '@:merchant.merchantCode'
  }
}
