import Vue from 'vue'

Vue.directive('loadmore', {
  bind(el, binding) {
    const selectWrap = el.querySelector('.el-table__body-wrapper')
    selectWrap.addEventListener('scroll', function () {
      let sign = 0.3
      const scrollDistance = this.scrollHeight - this.scrollTop - this.clientHeight
      if (this.scrollTop > 0 && scrollDistance <= sign) {
        binding.value()
      }
    })
  }
})

Vue.directive('input-focus', {
  bind(el) {
    const selectWraps = el.querySelectorAll('.van-cell')
    selectWraps.forEach((element) => {
      element.addEventListener('click', function () {
        const inputs = element.querySelectorAll('input')
        if (inputs && inputs.length == 1 && !element.__vue__.$attrs['no-focus']) {
          inputs[0].focus()
        }
      })
    })
  }
})
