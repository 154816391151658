import { EN, LOCAL_LANG } from '@/utils/constants'

export default {
  // root instance
  vm: null,

  // determine whether the page is a mobile terminal or a PC
  isPc: true,

  // show menu nav
  h5ShowMenu: false,

  // Public display area
  distributionInformation: '',

  // about language
  get lang() {
    let temp = localStorage.getItem(LOCAL_LANG)
    return temp ? temp : EN
  },
  set lang(val) {
    localStorage.setItem(LOCAL_LANG, val)
  },

  userInfo: {},

  loginLoading: false,

  vendorList: undefined
}
