<template>
  <ul style="display: block">
    <li
      v-for="(item, index) in tempList"
      :key="item.key"
    >
      <el-input
        v-model="item.value"
        maxlength="128"
        @blur="updateValue"
      />
      <i
        v-if="index === tempList.length - 1"
        class="el-icon-circle-plus"
        @click="addOne"
      ></i>
      <i
        v-if="tempList.length > 1"
        class="el-icon-remove"
        @click="removeOne(index)"
      ></i>
    </li>
  </ul>
</template>

<script>
import shortid from 'shortid'
export default {
  name: 'InputList',
  model: {
    prop: 'value',
    event: 'update'
  },
  props: {
    value: {
      type: Array,
      default: () => {
        return []
      }
    }
  },
  data() {
    return {
      tempList: []
    }
  },
  watch: {
    value: {
      handler(newArr) {
        let localArr = this.tempList.map((o) => o.value)
        if (!newArr.length || JSON.stringify(newArr) !== JSON.stringify(localArr)) this.init(newArr)
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    init(arr) {
      let list = []
      if (arr && arr.length) {
        list = arr.map((o) => {
          return {
            value: o,
            key: shortid.generate()
          }
        })
      } else {
        list.push({
          value: '',
          key: shortid.generate()
        })
      }
      this.tempList = list
      this.updateValue()
    },
    addOne() {
      this.tempList.push({
        value: '',
        key: shortid.generate()
      })
      this.updateValue()
    },
    removeOne(index) {
      this.tempList.splice(index, 1)
      this.updateValue()
    },
    updateValue() {
      let arr = this.tempList.map((o) => o.value)
      this.$emit('update', arr)
    }
  }
}
</script>

<style lang="scss" scoped>
.is-pc {
  li {
    width: 100%;
    display: flex;
    align-items: center;
    margin-bottom: 5px;
    i {
      font-size: 20px;
      margin-left: 5px;
      cursor: pointer;
    }
    .el-icon-remove {
      color: $danger;
    }
    .el-icon-circle-plus {
      color: $themeColor;
    }
  }
}
</style>
