import Vue from 'vue'
import preview from 'vue-photo-preview'
import 'vue-photo-preview/dist/skin.css'

const options = {
  fullscreenEl: true,
  closeEl: true,
  tapToClose: true,
  shareEl: false,
  zoomEl: true,
  counterEl: true,
  tapToToggleControls: true,
  clickToCloseNonZoomable: false,
  bgOpacity: 0.9,
  arrowEl: true,
  wheelToZoom: true,
  pinchToClose: false,
  closeOnVerticalDrag: false,
  indexIndicatorSep: ' / '
}
Vue.use(preview, options)
