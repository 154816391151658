import Vue from 'vue'
import { SET_LANG, SET_TIMEZONE, SET_VENDOR_LIST } from '../utils/constants'
import { updateUserTimezone, getVendorList } from '@/api'

export default {
  toSetLang({ commit, state }, lang) {
    return Vue.prototype
      .$changeLang(lang)
      .then(() => {
        commit(SET_LANG, lang)
      })
      .catch(() => {
        commit(SET_LANG, state.lang)
      })
  },
  toSetTimezone({ commit, state }, timezone) {
    return updateUserTimezone({
      userId: state.userInfo.userId,
      timezone
    }).then(({ data }) => {
      commit(SET_TIMEZONE, data.timezone)
    })
  },
  toSetVendorList({ commit }) {
    return getVendorList().then((res) => {
      const { data } = res
      if (Array.isArray(data)) {
        commit(SET_VENDOR_LIST, data)
      }
    })
  }
}
