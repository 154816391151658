/**
 * @desc define tool functions
 */

import Vue from 'vue'
import Crypto from './crypto'
import {
  CURRENT_USER,
  MY_TOKEN,
  CLEAR_USER,
  CLEAR_PERMISSION,
  CLEAR_MENU,
  CLEAR_DEFAULT_MENU
} from './constants'
import store from '@/store'
import { initialRouter } from '@/router'
import { clearMenus } from '@/utils/recursion-router'

class Tools {
  constructor() {}

  // userName
  get user() {
    let user = sessionStorage.getItem(CURRENT_USER)
    return user ? user : localStorage.getItem(CURRENT_USER)
  }
  set user(name) {
    if (name) {
      sessionStorage.setItem(CURRENT_USER, name)
      localStorage.setItem(CURRENT_USER, name)
    } else {
      sessionStorage.removeItem(CURRENT_USER)
      localStorage.removeItem(CURRENT_USER)
    }
  }

  // token
  get token() {
    const itemName = MY_TOKEN + (this.user ? '_' + this.user : '')
    return localStorage.getItem(itemName)
  }
  set token(token) {
    const itemName = MY_TOKEN + (this.user ? '_' + this.user : '')
    if (token) {
      localStorage.setItem(itemName, token)
    } else {
      localStorage.removeItem(itemName)
    }
  }

  /**
   * @method
   * @desc Judge whether it is an object
   * @param {*} val
   * @returns {boolean}
   */
  isObj(val) {
    return Object.prototype.toString.call(val) === '[object Object]'
  }

  /**
   * @method
   * @desc clear user default info
   */
  clearDefaultInfo() {
    clearMenus()
    store.commit(CLEAR_USER)
    store.commit('permission/' + CLEAR_PERMISSION)
    store.commit('permission/' + CLEAR_MENU)
    store.commit('permission/' + CLEAR_DEFAULT_MENU)
    this.user = null
    this.token = null
    // when logout need init router
    // eslint-disable-next-line no-import-assign
    // router = initialRouter()
    initialRouter()
    // console.log('call reset router, after reset router is ', router.getRoutes())
  }

  /**
   * @method
   * @desc catch async await error
   * @param {promise} promise
   * @returns {promise}
   */
  to(promise) {
    return promise
      .then((res) => {
        return [null, res]
      })
      .catch((err) => {
        console.log(err)
        return [err, null]
      })
  }

  /**
   * @method
   * @desc copy text
   * @param {text}
   */
  copyToClipboard(text) {
    const textarea = document.createElement('textarea')
    textarea.value = text
    document.body.appendChild(textarea)
    textarea.select()
    document.execCommand('copy')
    document.body.removeChild(textarea)
  }

  /**
   * @method
   * @desc get label through value
   * @param {object} options selectOptions
   * @param {string} value
   * @returns {string} label
   */
  getLabel(options, value) {
    let v = '--'
    try {
      let temp = options[value]
      v = temp ? temp : '--'
    } catch (err) {
      v = '--'
    }
    return v
  }

  /**
   * @method
   * @desc prevent users from continuously triggering events
   * @param {function} callback events
   * @param {number} wait time, unit: ms
   */
  throttle(callback, wait) {
    let start = 0
    return function (e) {
      let now = Date.now()
      if (now - start >= wait) {
        callback.call(this, e)
        start = now
      }
    }
  }

  debounce(callback, wait = 0) {
    let timer = null
    return function (e) {
      if (timer) clearTimeout(timer)
      timer = setTimeout(() => {
        callback.call(this, e)
      }, wait)
    }
  }

  deepClone(obj) {
    let newObj = null
    if (typeof obj === 'object' && obj !== null) {
      newObj = obj instanceof Array ? [] : {}
      for (let i in obj) {
        newObj[i] = typeof obj[i] === 'object' ? this.deepClone(obj[i]) : obj[i]
      }
    } else {
      newObj = obj
    }
    return newObj
  }

  /**
   * @method
   * @desc array de duplication
   * @param {array} arr
   * @return {array}
   */
  uniqueArray(arr) {
    return Array.from(new Set(arr))
  }

  /**
   * @method
   * @desc show tip message
   * @param {object} options config
   * @example
   * {
   *    msg: 'tip text',
   *    duration: 'unit: ms. defaule: 3000ms. 0 => not disappear',
   *    type: 'for pc => info / success / error. default: info',
   *    onClose: function executed when closing
   * }
   */
  msg(options) {
    this.closeLoading()
    // const { isPc } = store.state
    if (typeof options === 'string') {
      options = {
        msg: options
      }
    } else {
      options = this.isObj(options) ? options : {}
    }

    Vue.prototype.$msg(options)
    // if (isPc) {
    //     Vue.prototype.$message({
    //         message,
    //         duration,
    //         type
    //     })
    // } else {
    //     Vue.prototype.$toast({
    //         message,
    //         duration
    //     })
    // }
  }

  LQDConfirm(options) {
    this.closeLoading()
    if (typeof options === 'string') {
      options = {
        msg: options
      }
    } else {
      options = this.isObj(options) ? options : {}
    }

    return Vue.prototype.$LQDConfirm(options)
  }

  /**
   * @method
   * @desc show fullscreen loading
   * @param {object} options config
   * @example
   * {
   *    text: 'loading text',
   *    lock: 'forbid click background',
   *    duration: 'for h5, unit: ms, default: 0 => not disappear'
   * }
   * @return {object} loading instance, loading can be closed by instance`s close method
   */
  showLoading(options) {
    // const { isPc } = store.state
    let loading = null
    options = this.isObj(options) ? options : {}
    let { text, lock } = options
    text = text ? text : ''
    lock = lock === undefined ? true : false
    // let { duration } = options
    // duration = duration ? duration : 0

    loading = Vue.prototype.$loading({
      text,
      lock
    })

    // if (isPc) {
    //     loading = Vue.prototype.$loading({
    //         text,
    //         lock,
    //         background: 'rgba(0, 0, 0, 0.7)'
    //     })
    // } else {
    //     loading = Vue.prototype.$toast.loading({
    //         message: text,
    //         forbidClick: lock,
    //         duration
    //     })
    //     loading.close = loading.clear
    // }
    this.loading = loading
  }

  /**
   * @method
   * @desc close fullscreen loading
   */
  closeLoading() {
    if (this.loading) {
      this.loading.close()
      this.loading = null
    }
  }

  /**
   * @method
   * @desc default null key in object||array  => '--'
   */
  defaultProperty(item) {
    if (this.isObj(item)) {
      Object.keys(item).forEach((key) => {
        if (
          item[key] === '' ||
          item[key] === undefined ||
          item[key] === null ||
          item[key] === 'null'
        ) {
          item[key] = '--'
        } else if (this.isObj(item[key]) || Array.isArray(item[key])) {
          this.defaultProperty(item[key])
        }
      })
    } else if (Array.isArray(item)) {
      item.forEach((arr) => {
        if (arr === '' || arr === undefined || arr === null || arr === 'null') {
          arr = '--'
        } else if (this.isObj(arr) || Array.isArray(arr)) {
          this.defaultProperty(arr)
        }
      })
    }
    return item
  }

  /**
   * @method
   * @desc remove null key in object
   */
  removeProperty(obj) {
    Object.keys(obj).forEach((item) => {
      if (obj[item] === '' || obj[item] === undefined || obj[item] === null || obj[item] === 'null')
        delete obj[item]
    })
    return obj
  }

  removeEmptyArray(item) {
    if (this.isObj(item)) {
      Object.keys(item).forEach((key) => {
        if (Array.isArray(item[key]) && !item[key].length) {
          delete item[key]
        } else if (this.isObj(item[key]) || Array.isArray(item[key])) {
          this.removeEmptyArray(item[key])
        }
      })
    } else if (Array.isArray(item)) {
      item.forEach((arr, index) => {
        if (Array.isArray(arr) && !arr.length) {
          delete item[index]
        } else if (this.isObj(arr) || Array.isArray(arr)) {
          this.removeEmptyArray(arr)
        }
      })
    }
    return item
  }

  /**
   * @method
   * @desc remove null key in object
   * @example
   * {
   *    tableHeight: 'table clientHeight',
   *    tableHeaderHeight: 'table header height',
   *    trHeight: 'every tr height'
   * }
   */
  conputedPageSize(tableHeight, tableHeaderHeight, trHeight) {
    return Math.floor((tableHeight - tableHeaderHeight) / trHeight)
  }

  /**
   * @method
   * @desc remove null key in object
   * @example
   * {
   *    tableHeight: 'table clientHeight',
   *    tableHeaderHeight: 'table header height',
   *    trHeight: 'every tr height'
   * }
   */
  isImage(fileName) {
    if (!fileName) return false
    const imgs = ['png', 'jpg', 'jpeg', 'bmp', 'gif', 'webp', 'psd', 'tiff']
    var index = fileName.lastIndexOf('.')
    var ext = fileName.substr(index + 1)
    return imgs.indexOf(ext.toLowerCase()) !== -1
  }
}

export const crypto = new Crypto()
export default new Tools()
export * from './common'
export * from './form'
export * from './formatter'
