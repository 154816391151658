import {
  TOPUP_STATUS_PROCESSING,
  TOPUP_STATUS_COMPLETED,
  TOPUP_STATUS_REJECTED,
  TOPUP_STATUS_CANCELED,
  TOPUP_STATUS_FAILED,
  TRANSFER_OUT_STATUS_PROCESSING,
  TRANSFER_OUT_STATUS_COMPLETED,
  TRANSFER_OUT_STATUS_IN_TRANSFER,
  TRANSFER_OUT_STATUS_REJECTED,
  TRANSFER_OUT_STATUS_CANCELED,
  TRANSFER_OUT_STATUS_PAYMENT_FAILED,
  TRANSFER_OUT_STATUS_FAILED,
  EXCHANGE_STATUS_WAIT_PROOF,
  EXCHANGE_STATUS_PROCESSING,
  EXCHANGE_STATUS_COMPLETED,
  EXCHANGE_STATUS_REJECTED,
  EXCHANGE_STATUS_CANCELED,
  EXCHANGE_STATUS_FAILED,
  EXCHANGE_STATUS_WAIT_EXCHANGE_RATE,
  EXCHANGE_STATUS_WAIT_CONFIRM_CONTRACT,
  EXCHANGE_TYPE_PAY_IN,
  EXCHANGE_TYPE_PAY_OUT,
  EXCHANGE_TYPE_OFFLINE,
  SUB_ACCOUNT_PROCESSING,
  SUB_ACCOUNT_COMPLETED,
  SUB_ACCOUNT_REJECTED,
  SUB_ACCOUNT_FAILED,
  PAY_IN,
  PAY_OUT,
  MARKET_PLACE_ORDERS,
  SET_ACCOUNTS
} from '@/utils/constants'

import { accountQueryList } from '@/api'
export default {
  namespaced: true,
  state: {
    accountOptions: []
  },
  getters: {
    topUpStatus(state, getters, rootState) {
      const { $t } = rootState.vm
      return {
        [TOPUP_STATUS_PROCESSING]: $t('account.accountStatus.processing'),
        [TOPUP_STATUS_COMPLETED]: $t('account.accountStatus.completed'),
        [TOPUP_STATUS_REJECTED]: $t('account.accountStatus.rejected'),
        [TOPUP_STATUS_CANCELED]: $t('account.accountStatus.canceled'),
        [TOPUP_STATUS_FAILED]: $t('account.accountStatus.failed')
      }
    },
    transferOutStatus(state, getters, rootState) {
      const { $t } = rootState.vm
      return {
        [TRANSFER_OUT_STATUS_PROCESSING]: $t('account.accountStatus.processing'),
        [TRANSFER_OUT_STATUS_COMPLETED]: $t('account.accountStatus.completed'),
        [TRANSFER_OUT_STATUS_IN_TRANSFER]: $t('account.accountStatus.inTransfer'),
        [TRANSFER_OUT_STATUS_REJECTED]: $t('account.accountStatus.rejected'),
        [TRANSFER_OUT_STATUS_CANCELED]: $t('account.accountStatus.canceled'),
        [TRANSFER_OUT_STATUS_PAYMENT_FAILED]: $t('account.accountStatus.paymentFailed'),
        [TRANSFER_OUT_STATUS_FAILED]: $t('account.accountStatus.failed')
      }
    },
    exchangeStatus(state, getters, rootState) {
      const { $t } = rootState.vm
      return {
        [EXCHANGE_STATUS_WAIT_PROOF]: $t('account.accountStatus.waitProof'),
        [EXCHANGE_STATUS_WAIT_EXCHANGE_RATE]: $t('account.accountStatus.waitExchangeRate'),
        [EXCHANGE_STATUS_WAIT_CONFIRM_CONTRACT]: $t('account.accountStatus.waitConfirmContract'),
        [EXCHANGE_STATUS_PROCESSING]: $t('account.accountStatus.processing'),
        [EXCHANGE_STATUS_COMPLETED]: $t('account.accountStatus.completed'),
        [EXCHANGE_STATUS_REJECTED]: $t('account.accountStatus.rejected'),
        [EXCHANGE_STATUS_CANCELED]: $t('account.accountStatus.canceled'),
        [EXCHANGE_STATUS_FAILED]: $t('account.accountStatus.failed')
      }
    },
    subMerchantStatus(state, getters, rootState) {
      const { $t } = rootState.vm
      return {
        [SUB_ACCOUNT_PROCESSING]: $t('account.accountStatus.processing'),
        [SUB_ACCOUNT_COMPLETED]: $t('account.accountStatus.completed'),
        [SUB_ACCOUNT_REJECTED]: $t('account.accountStatus.rejected'),
        [SUB_ACCOUNT_FAILED]: $t('account.accountStatus.failed')
      }
    },
    exchangeModeTypes(state, getters, rootState) {
      const { $t } = rootState.vm
      return {
        [EXCHANGE_TYPE_PAY_IN]: $t('account.form.typePayin'),
        [EXCHANGE_TYPE_PAY_OUT]: $t('account.form.typePayout'),
        [EXCHANGE_TYPE_OFFLINE]: $t('account.form.typeOffline')
      }
    },

    withdrawsModeTypes(state, getters, rootState) {
      const { $t } = rootState.vm
      return {
        [PAY_IN]: $t('account.form.typePayin'),
        [PAY_OUT]: $t('account.form.typePayout')
      }
    },

    topupModeTypes(state, getters, rootState) {
      const { $t } = rootState.vm
      return {
        [PAY_OUT]: $t('account.form.typePayout'),
        [MARKET_PLACE_ORDERS]: $t('account.form.typeMarketPlace')
      }
    },

    merchantToAccount: (state) => (merchantId) => {
      const { accountOptions } = state
      return accountOptions.filter((o) => o.merchantId == merchantId)
    },

    countryToAccount: (state) => (merchantId, country) => {
      const { accountOptions } = state
      return accountOptions.filter((o) => o.merchantId == merchantId && o.countryCode == country)
    }
  },
  mutations: {
    [SET_ACCOUNTS](state, val) {
      state.accountOptions = val
    }
  },
  actions: {
    fetchAccounts({ commit }) {
      accountQueryList().then((res) => {
        const list = res.data || []
        commit(SET_ACCOUNTS, list)
      })
    }
  }
}
