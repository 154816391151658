import router, { freePermissionRoutes } from './'
import store from '@/store'
import { i18n } from '@/i18n'
import tools from '@/utils'
import { menus } from '@/utils/recursion-router'
import {
  SET_DEFAULT_MENU,
  CHANGE_COUNTRY,
  SET_LOGIN_LOADING,
  SET_HEAD_INFORMATION,
  RESET_PSW_SCENE
} from '@/utils/constants'

router.beforeEach(async (to, from, next) => {
  // init lang
  const { lang } = store.state
  if (lang !== i18n.locale) {
    await store.dispatch('toSetLang', lang)
  } else {
    let html = document.querySelector('html')
    if (html.getAttribute('lang') !== lang) {
      html.setAttribute('lang', lang)
    }
  }

  let { name, path, fullPath, meta } = to
  if (to.name === 'login' && from.name !== to.name) {
    tools.clearDefaultInfo()
    next()
    return
  }

  if (freePermissionRoutes.some((r) => r.name === name)) {
    next()
    return
  }

  if (!tools.token) {
    // user not logged in
    // routes will be initial if user not logged in
    // so router.getRoutes() will get free permission routes
    if (
      (path === '/' || meta.requireAuth || !freePermissionRoutes.some((r) => r.name === name)) &&
      to.name !== 'login'
    ) {
      // the route need perission, redirect to login
      next('/login/form')
    } else {
      const reg = new RegExp(/^(\/login(\/{.}*)*)|(\/404)$/)
      if (reg.test(path)) {
        next()
      } else {
        next({
          path: '/404',
          replace: true
        })
      }
    }
  } else {
    // user logged in
    if (!store.state.permission.permissionList) {
      // need to get permission
      store
        .dispatch('permission/fetchPermission')
        .then(() => {
          beforeNext(next, path, fullPath)
        })
        .catch((err) => {
          // console.log('fetch permission failed')
          if (store.state.loginLoading) {
            store.commit(SET_LOGIN_LOADING, false)
          }
          // if user login success, user can go to reset password page, no need permissionList
          if (err) {
            const { code, data } = err
            // force user change password
            if (code === 600040 && router?.currentRoute?.name !== 'resetPsw') {
              const firstLogin = data.firstLogin
              const tmpRouteObj = {
                path: `/login/resetPsw`,
                query: { scene: RESET_PSW_SCENE.EXPIRED }
              }
              if (firstLogin) {
                // Is it first time login
                tmpRouteObj.query.scene = RESET_PSW_SCENE.INITIAL
              }
              router.replace(tmpRouteObj)
              return
            }
          }
          if (from.name === 'login') {
            tools.clearDefaultInfo()
            next(false)
          } else {
            next('/login/form')
          }
        })
    } else {
      if (meta.hide === true && localStorage.getItem(CHANGE_COUNTRY) == 1) {
        // Switch countries on the hidden page
        localStorage.removeItem(CHANGE_COUNTRY)
        beforeNext(next, '/home', '/home')
        return
      }
      beforeNext(next, path)
    }
  }
})

function beforeNext(next, path, fullPath) {
  if (menus.includes(path)) {
    store.commit('permission/' + SET_DEFAULT_MENU, path)
  }
  if (store.state.loginLoading) {
    store.commit(SET_LOGIN_LOADING, false)
  }
  if (fullPath) {
    next({
      path: fullPath,
      replace: true
    })
  } else {
    next()
  }

  //Clear public information display
  store.commit(SET_HEAD_INFORMATION, '')
}
