import {
  SET_VM,
  SET_IS_PC,
  SET_LANG,
  SET_USER,
  CLEAR_USER,
  SET_H5_SHOW_MENU,
  SET_LOGIN_LOADING,
  SET_HEAD_INFORMATION,
  SET_TIMEZONE,
  SET_VENDOR_LIST
} from '../utils/constants'

export default {
  [SET_VM](state, val) {
    state.vm = val
  },
  [SET_IS_PC](state, val) {
    state.isPc = val
  },
  [SET_LANG](state, val) {
    state.lang = val
  },
  [SET_USER](state, val) {
    state.userInfo = val
  },
  [CLEAR_USER](state) {
    state.userInfo = {}
  },
  [SET_H5_SHOW_MENU](state, val) {
    state.h5ShowMenu = val
  },
  [SET_LOGIN_LOADING](state, val) {
    state.loginLoading = val
  },
  [SET_HEAD_INFORMATION](state, val) {
    state.distributionInformation = val
  },
  [SET_TIMEZONE](state, val) {
    state.userInfo = {
      ...state.userInfo,
      timeZone: val
    }
  },
  [SET_VENDOR_LIST](state, val) {
    state.vendorList = val
  }
}
