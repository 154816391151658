/**
 * @desc Define encryption and decryption functions
 */

import CryptoJs from 'crypto-js'

class Crypto {
  constructor() {}

  /**
   * @method
   * @desc SHA256 encript
   * @param {string} psw text that needs to be encripted
   * @returns {string} encrypted text
   */
  SHA256(psw) {
    return CryptoJs.SHA256(psw).toString()
  }

  /**
   * @method
   * @desc AES encript
   * @param {string} psw text that needs to be encripted
   * @param {string} key secret key
   * @return {promise} encrypted text
   */
  AESEncript(psw, key) {
    return CryptoJs.AES.encrypt(psw, CryptoJs.enc.Utf8.parse(key), {
      mode: CryptoJs.mode.ECB,
      padding: CryptoJs.pad.Pkcs7
    }).toString()
  }

  /**
   * @method
   * @desc AES decript
   * @param {string} psw encrypted text
   * @param {string} key secret key
   * @return {promise} decrypted text
   */
  AESDecript(psw, key) {
    return CryptoJs.AES.decrypt(psw, CryptoJs.enc.Utf8.parse(key), {
      mode: CryptoJs.mode.ECB,
      padding: CryptoJs.pad.Pkcs7
    }).toString(CryptoJs.enc.Utf8)
  }
}

export default Crypto
