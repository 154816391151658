import {
  EN,
  ZH,
  TERMINAL_WEB,
  TERMINAL_LARK,
  TERMINAL_MV,
  US,
  BR,
  MX,
  CO,
  CL,
  SPEI,
  SPEI_VA,
  SPEI_BANK_TRANSFER,
  TED,
  PIX,
  CARD,
  BOLETO,
  OXXO,
  GIFTCARD,
  TOPUP,
  UTILITY,
  PAY_CASH,
  PIC_PAY,
  PAYPAL,
  MERCADO_PAGO,
  AME_QR,
  CO_BANK_TRANSFER,
  CL_BANK_TRANSFER,
  PSE,
  NEQUI,
  BANCOLOMBIA_BUTTON,
  EFECTY,
  BANCOLOMBIA_COLLECTION,
  PIX_QR_CODE,
  MULTICAJA_TRANSFER,
  MULTICAJA_CASH,
  HITES,
  FPAY,
  CHEK,
  MACH,
  PAGO46,
  KHIPU,
  WEB_PAY,
  BUSINESS_TRANSACTION,
  BUSINESS_TOPUP,
  BUSINESS_REFUND,
  BUSINESS_TRANSFER_OUT,
  BUSINESS_EXCHANGE,
  BUSINESS_ADJUSTMENT,
  BUSINESS_SUPPLEMENT,
  DIRECTION_SETTLED,
  DIRECTION_REFUND,
  DIRECTION_REJECTED,
  DIRECTION_CHARGE_BACK,
  DIRECTION_CHARGE_BACK_REJECTED
} from '@/utils/constants'

export default {
  // all countries
  countries(state) {
    const { $t } = state.vm
    return {
      [US]: $t(`country.${US}`),
      [BR]: $t(`country.${BR}`),
      [MX]: $t(`country.${MX}`),
      [CO]: $t(`country.${CO}`),
      [CL]: $t(`country.${CL}`)
    }
  },

  // all languages
  langs(state) {
    const { $t } = state.vm
    return {
      [EN]: $t('langs.en'),
      [ZH]: $t('langs.zh')
    }
  },

  // all languages
  h5Langs(state) {
    const { $t } = state.vm
    return [
      {
        value: EN,
        label: $t('langs.en')
      },
      {
        value: ZH,
        label: $t('langs.zh')
      }
    ]
  },

  // all terminal
  terminals() {
    return {
      [TERMINAL_WEB]: 'Web',
      [TERMINAL_LARK]: 'Lark',
      [TERMINAL_MV]: 'MV'
    }
  },

  // In the corresponding path: src/assets/images/pc/
  productTypeSummary(state) {
    const { $t } = state.vm
    return {
      [SPEI]: {
        label: $t(`productType.${SPEI}`),
        homeIcon: 'home_SPEI',
        tableIcon: 'transactions_SPEI',
        signLogo: 'product_sign_SPEI'
      },
      [SPEI_VA]: {
        label: $t(`productType.${SPEI_VA}`),
        homeIcon: 'home_SPEI',
        tableIcon: 'transactions_SPEI',
        signLogo: 'product_sign_SPEI'
      },
      [SPEI_BANK_TRANSFER]: {
        label: $t(`productType.${SPEI_BANK_TRANSFER}`),
        homeIcon: 'home_SPEI',
        tableIcon: 'transactions_SPEI',
        signLogo: 'product_sign_SPEI'
      },
      [TED]: {
        label: $t(`productType.${TED}`),
        homeIcon: 'home_TED',
        tableIcon: 'transactions_TED',
        signLogo: 'product_sign_TED'
      },
      [PIX]: {
        label: $t(`productType.${PIX}`),
        homeIcon: 'home_PIX',
        tableIcon: 'transactions_PIX',
        signLogo: 'product_sign_PIX'
      },
      [PIX_QR_CODE]: {
        label: $t(`productType.${PIX_QR_CODE}`),
        homeIcon: 'home_PIX',
        tableIcon: 'transactions_PIX',
        signLogo: 'product_sign_PIX'
      },
      [CARD]: {
        label: $t(`productType.${CARD}`),
        homeIcon: 'home_CARD',
        tableIcon: 'transactions_CARD',
        signLogo: 'product_sign_CARD'
      },
      [BOLETO]: {
        label: $t(`productType.${BOLETO}`),
        homeIcon: 'home_BOLETO',
        tableIcon: 'transactions_BOLETO',
        signLogo: 'product_sign_BOLETO'
      },
      [OXXO]: {
        label: $t(`productType.${OXXO}`),
        homeIcon: 'home_OXXO',
        tableIcon: 'transactions_OXXO',
        signLogo: 'product_sign_OXXO'
      },
      [GIFTCARD]: {
        label: $t(`productType.${GIFTCARD}`),
        homeIcon: 'home_GIFTCARD',
        tableIcon: 'transactions_GIFTCARD',
        signLogo: 'product_sign_GIFTCARD'
      },
      [TOPUP]: {
        label: $t(`productType.${TOPUP}`),
        homeIcon: 'home_TOPUP',
        tableIcon: 'transactions_TOPUP',
        signLogo: 'product_sign_TOPUP'
      },
      [UTILITY]: {
        label: $t(`productType.${UTILITY}`),
        homeIcon: 'home_UTILITY',
        tableIcon: 'transactions_UTILITY',
        signLogo: 'product_sign_UTILITY'
      },
      [PAY_CASH]: {
        label: $t(`productType.${PAY_CASH}`),
        homeIcon: 'home_PAY_CASH',
        tableIcon: 'transactions_PAY_CASH',
        signLogo: 'product_sign_PAY_CASH'
      },
      [PIC_PAY]: {
        label: $t(`productType.${PIC_PAY}`),
        homeIcon: 'home_PIC_PAY',
        tableIcon: 'transactions_PIC_PAY',
        signLogo: 'product_sign_PIC_PAY'
      },
      [PAYPAL]: {
        label: $t(`productType.${PAYPAL}`),
        homeIcon: 'home_PAYPAL',
        tableIcon: 'transactions_PAYPAL',
        signLogo: 'product_sign_PAYPAL'
      },
      [MERCADO_PAGO]: {
        label: $t(`productType.${MERCADO_PAGO}`),
        homeIcon: 'home_MERCADO_PAGO',
        tableIcon: 'transactions_MERCADO_PAGO',
        signLogo: 'product_sign_MERCADO_PAGO'
      },
      [AME_QR]: {
        label: $t(`productType.${AME_QR}`),
        homeIcon: 'home_AME_QR',
        tableIcon: 'transactions_AME_QR',
        signLogo: 'product_sign_AME_QR'
      },
      [CO_BANK_TRANSFER]: {
        label: $t(`productType.${CO_BANK_TRANSFER}`),
        homeIcon: 'home_CO_BANK_TRANSFER',
        tableIcon: 'transactions_CO_BANK_TRANSFER',
        signLogo: 'product_sign_CO_BANK_TRANSFER'
      },
      [CL_BANK_TRANSFER]: {
        label: $t(`productType.${CL_BANK_TRANSFER}`),
        homeIcon: 'home_CL_BANK_TRANSFER',
        tableIcon: 'transactions_CL_BANK_TRANSFER',
        signLogo: 'product_sign_CL_BANK_TRANSFER'
      },
      [PSE]: {
        label: $t(`productType.${PSE}`),
        homeIcon: 'home_PSE',
        tableIcon: 'transactions_PSE',
        signLogo: 'product_sign_PSE'
      },
      [NEQUI]: {
        label: $t(`productType.${NEQUI}`),
        homeIcon: 'home_NEQUI',
        tableIcon: 'transactions_NEQUI',
        signLogo: 'product_sign_NEQUI'
      },
      [BANCOLOMBIA_BUTTON]: {
        label: $t(`productType.${BANCOLOMBIA_BUTTON}`),
        homeIcon: 'home_CO_BANK_TRANSFER',
        tableIcon: 'transactions_CO_BANK_TRANSFER',
        signLogo: 'product_sign_CO_BANK_TRANSFER'
      },
      [EFECTY]: {
        label: $t(`productType.${EFECTY}`),
        homeIcon: 'home_EFECTY',
        tableIcon: 'transactions_EFECTY',
        signLogo: 'product_sign_EFECTY'
      },
      [BANCOLOMBIA_COLLECTION]: {
        label: $t(`productType.${BANCOLOMBIA_COLLECTION}`),
        homeIcon: 'home_BANCOLOMBIA_COLLECTION',
        tableIcon: 'transactions_BANCOLOMBIA_COLLECTION',
        signLogo: 'product_sign_BANCOLOMBIA_COLLECTION'
      },
      [MULTICAJA_TRANSFER]: {
        label: $t(`productType.${MULTICAJA_TRANSFER}`),
        homeIcon: 'home_MULTICAJA_TRANSFER',
        tableIcon: 'transactions_MULTICAJA_TRANSFER',
        signLogo: 'product_sign_MULTICAJA_TRANSFER'
      },
      [MULTICAJA_CASH]: {
        label: $t(`productType.${MULTICAJA_CASH}`),
        homeIcon: 'home_MULTICAJA_CASH',
        tableIcon: 'transactions_MULTICAJA_CASH',
        signLogo: 'product_sign_MULTICAJA_CASH'
      },
      [HITES]: {
        label: $t(`productType.${HITES}`),
        homeIcon: 'home_HITES',
        tableIcon: 'transactions_HITES',
        signLogo: 'product_sign_HITES'
      },
      [FPAY]: {
        label: $t(`productType.${FPAY}`),
        homeIcon: 'home_FPAY',
        tableIcon: 'transactions_FPAY',
        signLogo: 'product_sign_FPAY'
      },
      [CHEK]: {
        label: $t(`productType.${CHEK}`),
        homeIcon: 'home_CHEK',
        tableIcon: 'transactions_CHEK',
        signLogo: 'product_sign_CHEK'
      },
      [MACH]: {
        label: $t(`productType.${MACH}`),
        homeIcon: 'home_MACH',
        tableIcon: 'transactions_MACH',
        signLogo: 'product_sign_MACH'
      },
      [PAGO46]: {
        label: $t(`productType.${PAGO46}`),
        homeIcon: 'home_PAGO46',
        tableIcon: 'transactions_PAGO46',
        signLogo: 'product_sign_PAGO46'
      },
      [KHIPU]: {
        label: $t(`productType.${KHIPU}`),
        homeIcon: 'home_KHIPU',
        tableIcon: 'transactions_KHIPU',
        signLogo: 'product_sign_KHIPU'
      },
      [WEB_PAY]: {
        label: $t(`productType.${WEB_PAY}`),
        homeIcon: 'home_WEB_PAY',
        tableIcon: 'transactions_WEB_PAY',
        signLogo: 'product_sign_WEB_PAY'
      }
    }
  },

  businessType(state) {
    const { $t } = state.vm
    return {
      [BUSINESS_TRANSACTION]: $t(`businessType.${BUSINESS_TRANSACTION}`),
      [BUSINESS_TOPUP]: $t(`businessType.${BUSINESS_TOPUP}`),
      [BUSINESS_REFUND]: $t(`businessType.${BUSINESS_REFUND}`),
      [BUSINESS_TRANSFER_OUT]: $t(`businessType.${BUSINESS_TRANSFER_OUT}`),
      [BUSINESS_EXCHANGE]: $t(`businessType.${BUSINESS_EXCHANGE}`),
      [BUSINESS_ADJUSTMENT]: $t(`businessType.${BUSINESS_ADJUSTMENT}`),
      [BUSINESS_SUPPLEMENT]: $t(`businessType.${BUSINESS_SUPPLEMENT}`)
    }
  },

  directionType(state) {
    const { $t } = state.vm
    return {
      [DIRECTION_SETTLED]: $t(`directionType.${DIRECTION_SETTLED}`),
      [DIRECTION_REFUND]: $t(`directionType.${DIRECTION_REFUND}`),
      [DIRECTION_REJECTED]: $t(`directionType.${DIRECTION_REJECTED}`),
      [DIRECTION_CHARGE_BACK]: $t(`directionType.${DIRECTION_CHARGE_BACK}`),
      [DIRECTION_CHARGE_BACK_REJECTED]: $t(`directionType.${DIRECTION_CHARGE_BACK_REJECTED}`)
    }
  },

  isSuperAdmin(state) {
    const { userInfo } = state
    return userInfo.superAdmin
  },

  timezone(state) {
    const { timeZone } = state.userInfo
    return timeZone ? Number(timeZone.replace('UTC', '')) : 0
  }
}
