import store from '@/store'

export const openNewWindow = (router, params) => {
  params = typeof params === 'object' ? params : { path: params }
  const query = params.query || (params.query = {})
  query.hideBack = 1
  const { href } = router.resolve(params)
  window.open(href, '_blank')
}

export const tTypes = (types) => {
  if (Array.isArray(types)) {
    const { $t } = store.state.vm
    return types.map((t) => ({ ...t, label: $t(t.label) }))
  }
}
