import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import { i18n, loadLanguageAsync } from './i18n'
import FastClick from 'fastclick'
import './assets/scss/frame.scss'
import './assets/element-theme/index.css'
import './assets/icon-font/iconfont.css'
import './assets/icon-font/iconfont.js'
import './plugins/lib-flexible'
import './plugins/element-ui'
import './plugins/vant'
import './plugins/global-components'
import './plugins/day-js'
import './plugins/photo-preview'
import './router/permission'
import './utils/directive.js'
import './utils/vuePrototype.js'
import { SET_VM } from './utils/constants'
import filters from './utils/filters'

FastClick.attach(document.body)

let deviceIsWindowsPhone = navigator.userAgent.indexOf('Windows Phone') >= 0
let deviceIsIOS = /iP(ad|hone|od)/.test(navigator.userAgent) && !deviceIsWindowsPhone

FastClick.prototype.focus = function (targetElement) {
  let length

  // Issue #160: on iOS 7, some input elements (e.g. date datetime month) throw a vague TypeError on setSelectionRange. These elements don't have an integer value for the selectionStart and selectionEnd properties, but unfortunately that can't be used for detection because accessing the properties also throws a TypeError. Just check the type instead. Filed as Apple bug #15122724.
  if (
    deviceIsIOS &&
    targetElement.setSelectionRange &&
    targetElement.type.indexOf('date') !== 0 &&
    targetElement.type !== 'time' &&
    targetElement.type !== 'month' &&
    targetElement.type !== 'email'
  ) {
    length = targetElement.value.length
    targetElement.focus()
    targetElement.setSelectionRange(length, length)
  } else {
    targetElement.focus()
  }
}

Object.keys(filters).forEach((key) => {
  Vue.filter(key, filters[key])
})

Vue.config.productionTip = false

Vue.prototype.$changeLang = loadLanguageAsync

let vm = new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App)
}).$mount('#app')

store.commit(SET_VM, vm)
