import permission from './permission'
import date from './date'
import feedback from './feedback'
import transactions from './transactions'
import account from './account'
import payment from './payment'
import merchant from './merchant'

export default {
  permission,
  date,
  feedback,
  transactions,
  account,
  payment,
  merchant
}
