/**
 * @desc define global components
 */

import Vue from 'vue'
import ThePagination from '@/components/ThePagination'
import TheSearch from '@/components/TheSearch'
import TheSearchList from '@/components/TheSearchList'
import TheSearchList2 from '@/components/TheSearchList2'
import Echarts from '@/components/Echarts'
import Msg from '@/components/Msg'
import '@/components/ConfirmBox/index.js'
import FileList from '@/components/FileList'
import Upload from '@/components/Upload'

import TheH5Search from '@/components/TheH5Search'
import TheH5MultipleList from '@/components/TheH5MultipleList'
import H5TreeSelector from '@/components/H5TreeSelector'
import TheH5DatePicker from '@/components/TheH5DatePicker'
import Dialog from '@/components/Dialog'
import InputList from '@/components/InputList'

function createComponent(name, component) {
  return {
    install(Vue) {
      Vue.component(name, component)
    }
  }
}

Vue.use(createComponent('ThePagination', ThePagination))
Vue.use(createComponent('TheSearch', TheSearch))
Vue.use(createComponent('TheSearchList', TheSearchList))
Vue.use(createComponent('TheSearchList2', TheSearchList2))
Vue.use(createComponent('Echarts', Echarts))
Vue.use(createComponent('Upload', Upload))
Vue.use(createComponent('FileList', FileList))
Vue.use(createComponent('TheH5Search', TheH5Search))
Vue.use(createComponent('TheH5MultipleList', TheH5MultipleList))
Vue.use(createComponent('H5TreeSelector', H5TreeSelector))
Vue.use(createComponent('TheH5DatePicker', TheH5DatePicker))
Vue.use(Dialog)
Vue.use(createComponent('InputList', InputList))

Vue.prototype.$msg = Msg
