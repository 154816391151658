/**
 * @desc contains all dynamic routes that require permission control
 * meta: {
 *  route: 'route path, and used to match with back end data',
 *  title: 'route title key, the corresponding text needs to be defined in the language json',
 *  icon: 'route icon',
 *  activeIcon: 'route active icon'
 *  hoverIcon: 'route hover icon'
 *  requireAuth: 'boolean, true => need permission',
 *  hide: 'boolean, true => do not show in menu',
 *  hideMenu: 'boolean, true => do not display menu',
 *  btns: 'array, button permissions',
 *  links: 'array,links permissions'
 * }
 */

const dynamicRoutes = [
  // Merchant
  {
    path: 'merchant',
    meta: {
      route: '/merchant',
      icon: require('@/assets/images/pc/menu_merchant_icon.png'),
      activeIcon: require('@/assets/images/pc/menu_merchant_active_icon.png'),
      h5Icon: require('@/assets/images/h5/menu_merchant_icon.png'),
      h5ActiveIcon: require('@/assets/images/h5/menu_merchant_active_icon.png')
    },
    component: {
      render: (c) => c('router-view')
    },
    children: [
      {
        path: 'manage',
        meta: {
          route: '/merchant/manage',
          icon: '',
          requireAuth: true
        },
        component: () => import('@/views/Merchant/Manage.vue')
      },
      {
        path: 'detail/:id',
        meta: {
          route: '/merchant/detail',
          title: '__web_merchant_detail',
          icon: '',
          hide: true,
          hideMenu: true,
          requireAuth: true
        },
        component: () => import('@/views/Merchant/detail/index.vue')
      }
    ]
  },
  // Account
  {
    path: 'account',
    meta: {
      route: '/account',
      icon: require('@/assets/images/pc/menu_account_icon.png'),
      activeIcon: require('@/assets/images/pc/menu_account_active_icon.png'),
      h5Icon: require('@/assets/images/h5/menu_account_icon.png'),
      h5ActiveIcon: require('@/assets/images/h5/menu_account_active_icon.png')
    },
    component: {
      render: (c) => c('router-view')
    },
    children: [
      {
        path: 'manage',
        meta: {
          route: '/account/manage',
          icon: '',
          keepAlive: true,
          requireAuth: true
        },
        component: () => import('@/views/Account/Manage')
      },
      {
        path: 'payout-funding',
        meta: {
          route: '/account/payout-funding',
          icon: '',
          keepAlive: true,
          requireAuth: true
        },
        component: () => import('@/views/Account/PayoutFunding/index')
      },
      {
        path: 'funding-detail/:id',
        meta: {
          route: '/account/funding-detail',
          title: '__web_account_payout_funding_detail',
          icon: '',
          hide: true,
          hideMenu: true,
          requireAuth: true
        },
        component: () => import('@/views/Account/PayoutFunding/detail.vue')
      },
      {
        path: 'withdraws-application',
        meta: {
          route: '/account/withdraws-application',
          icon: '',
          keepAlive: true,
          requireAuth: true
        },
        component: () => import('@/views/Account/Withdraws/index')
      },
      {
        path: 'withdraws-detail/:id',
        meta: {
          route: '/account/withdraws-detail',
          title: '__web_account_payout_withdraws_detail',
          icon: '',
          hide: true,
          hideMenu: true,
          requireAuth: true
        },
        component: () => import('@/views/Account/Withdraws/detail.vue')
      },
      {
        path: 'exchange',
        meta: {
          route: '/account/exchange',
          icon: '',
          keepAlive: true,
          requireAuth: true
        },
        component: () => import('@/views/Account/Exchange/index')
      },
      {
        path: 'exchange-detail/:id',
        meta: {
          route: '/account/exchange-detail',
          title: '__web_account_exchange_detail',
          icon: '',
          hide: true,
          hideMenu: true,
          requireAuth: true
        },
        component: () => import('@/views/Account/Exchange/detail.vue')
      },
      {
        path: 'sub-merchant',
        meta: {
          route: '/account/sub-merchant',
          icon: '',
          keepAlive: true,
          requireAuth: true
        },
        component: () => import('@/views/Account/SubMerchant')
      },
      {
        path: 'sub-merchant-detail/:id',
        meta: {
          route: '/account/sub-merchant-detail',
          title: '__web_account_manage_sub_merchant_detail',
          icon: '',
          hide: true,
          hideMenu: true,
          requireAuth: true
        },
        component: () => import('@/views/Account/SubMerchant/detail.vue')
      },
      {
        path: 'refund',
        meta: {
          route: '/account/refund',
          icon: '',
          keepAlive: true,
          requireAuth: true
        },
        component: () => import('@/views/Account/Refund/index')
      },
      {
        path: 'refund-detail/:id',
        meta: {
          route: '/account/refund-detail',
          title: '__web_account_refund_detail',
          icon: '',
          hide: true,
          hideMenu: true,
          requireAuth: true
        },
        component: () => import('@/views/Account/Refund/detail.vue')
      }
    ]
  },
  // Payment
  {
    path: 'payment',
    meta: {
      route: '/payment',
      icon: require('@/assets/images/pc/menu_payment_icon.png'),
      activeIcon: require('@/assets/images/pc/menu_payment_active_icon.png'),
      h5Icon: require('@/assets/images/h5/menu_payment_icon.png'),
      h5ActiveIcon: require('@/assets/images/h5/menu_payment_active_icon.png')
    },
    component: {
      render: (c) => c('router-view')
    },
    children: [
      {
        path: 'payout',
        meta: {
          route: '/payment/payout',
          icon: '',
          keepAlive: true,
          requireAuth: true
        },
        component: () => import('@/views/Payment/Payout')
      },
      {
        path: 'payout-detail/:id',
        meta: {
          route: '/payment/payout-detail',
          title: '__web_payment_payout_detail',
          icon: '',
          hide: true,
          hideMenu: true,
          requireAuth: true
        },
        component: () => import('@/views/Payment/Payout/detail.vue')
      }
    ]
  },
  // Configure
  {
    path: 'configure',
    meta: {
      route: '/configure',
      icon: require('@/assets/images/pc/menu_fee_config_icon.png'),
      activeIcon: require('@/assets/images/pc/menu_fee_config_active_icon.png')
      // h5Icon: require('@/assets/images/h5/menu_fee_config_icon.png'),
      // h5ActiveIcon: require('@/assets/images/h5/menu_fee_config_active_icon.png')
    },
    component: {
      render: (c) => c('router-view')
    },
    children: [
      {
        path: 'fee-config',
        meta: {
          route: '/configure/fee-config',
          icon: '',
          requireAuth: true
        },
        component: () => import('@/views/Config/FeeConfig.vue')
      },
      {
        path: 'cost-config',
        meta: {
          route: '/configure/cost-config',
          icon: '',
          requireAuth: true
        },
        component: () => import('@/views/Config/CostConfig.vue')
      },
      {
        path: 'auto-transfer',
        meta: {
          route: '/configure/auto-transfer',
          icon: '',
          requireAuth: true
        },
        component: () => import('@/views/Config/AutoTransfer.vue')
      }
    ]
  },
  // Data Center
  {
    path: 'data-center',
    meta: {
      route: '/data-center',
      icon: require('@/assets/images/pc/menu_data_center_icon.png'),
      activeIcon: require('@/assets/images/pc/menu_data_center_active_icon.png')
    },
    component: {
      render: (c) => c('router-view')
    },
    children: [
      {
        path: 'summary',
        meta: {
          route: '/data-center/summary',
          icon: '',
          requireAuth: true
        },
        component: () => import('@/views/DataCenter/SummaryView.vue')
      },
      {
        path: 'revenue',
        meta: {
          route: '/data-center/revenue',
          icon: '',
          requireAuth: true
        },
        component: () => import('@/views/DataCenter/RevenueView.vue')
      },
      {
        path: 'transaction-history',
        meta: {
          route: '/data-center/transaction-history',
          icon: '',
          requireAuth: true
        },
        component: () => import('@/views/DataCenter/TransactionHistory.vue')
      }
    ]
  },
  // Operation
  {
    path: 'operation',
    meta: {
      route: '/operation',
      icon: require('@/assets/images/pc/menu_merchant_icon.png'),
      activeIcon: require('@/assets/images/pc/menu_merchant_active_icon.png'),
      h5Icon: require('@/assets/images/h5/menu_merchant_icon.png'),
      h5ActiveIcon: require('@/assets/images/h5/menu_merchant_active_icon.png')
    },
    component: {
      render: (c) => c('router-view')
    },
    children: [
      {
        path: 'charge-back',
        meta: {
          route: '/operation/charge-back',
          icon: '',
          requireAuth: true,
          keepAlive: true
        },
        component: () => import('@/views/Operation/ChargeBack.vue')
      },
      {
        path: 'charge-back-detail/:id',
        props: true,
        meta: {
          route: '/operation/charge-back-detail',
          title: '__web_operation_charge_back_info',
          icon: '',
          hide: true,
          hideMenu: true,
          requireAuth: true
        },
        component: () => import('@/views/Operation/TransactionCaseComponents/ChargeBackDetail.vue')
      },
      {
        path: 'refund',
        meta: {
          route: '/operation/refund',
          icon: '',
          requireAuth: true,
          keepAlive: true
        },
        component: () => import('@/views/Operation/Refund.vue')
      },
      {
        path: 'refund-detail/:id',
        props: true,
        meta: {
          route: '/operation/refund-detail',
          title: '__web_operation_refund_info',
          icon: '',
          hide: true,
          hideMenu: true,
          requireAuth: true
        },
        component: () => import('@/views/Operation/TransactionCaseComponents/RefundDetail.vue')
      },
      {
        path: 'product-revenue',
        meta: {
          route: '/operation/product-revenue',
          icon: '',
          requireAuth: true
        },
        component: () => import('@/views/Operation/ProductRevenue.vue')
      }
    ]
  },
  // Support center
  {
    path: 'support-center',
    meta: {
      route: '/support-center',
      icon: require('@/assets/images/pc/menu_feedback_icon.png'),
      activeIcon: require('@/assets/images/pc/menu_feedback_active_icon.png'),
      h5Icon: require('@/assets/images/h5/menu_feedback_icon.png'),
      h5ActiveIcon: require('@/assets/images/h5/menu_feedback_active_icon.png')
    },
    component: {
      render: (c) => c('router-view')
    },
    children: [
      {
        path: 'case',
        meta: {
          route: '/support-center/case',
          icon: '',
          keepAlive: true,
          requireAuth: true
        },
        component: () => import('@/views/Feedback/Case.vue')
      },
      {
        path: 'case-detail/:id',
        props: true,
        meta: {
          route: '/support-center/case-detail',
          title: '__web_support_center_info_case',
          icon: '',
          hide: true,
          hideMenu: true,
          requireAuth: true
        },
        component: () => import('@/views/Feedback/CaseDetail.vue')
      },
      {
        path: 'issue-buckets',
        meta: {
          route: '/support-center/issue-buckets',
          icon: '',
          requireAuth: true
        },
        component: () => import('@/views/Feedback/IssueBuckets.vue')
      }
    ]
  },
  // KYC
  {
    path: 'kyc',
    meta: {
      route: '/kyc',
      icon: require('@/assets/images/pc/menu_kyc_icon.png'),
      activeIcon: require('@/assets/images/pc/menu_kyc_active_icon.png')
      // h5Icon: require('@/assets/images/h5/menu_feedback_icon.png'),
      // h5ActiveIcon: require('@/assets/images/h5/menu_feedback_active_icon.png')
    },
    component: {
      render: (c) => c('router-view')
    },
    children: [
      {
        path: 'manage',
        meta: {
          route: '/kyc/manage',
          icon: '',
          requireAuth: true
        },
        component: () => import('@/views/Kyc/Manage.vue')
      }
    ]
  },
  // IAM
  {
    path: 'access',
    meta: {
      route: '/access',
      icon: require('@/assets/images/pc/menu_iam_icon.png'),
      activeIcon: require('@/assets/images/pc/menu_iam_active_icon.png'),
      h5Icon: require('@/assets/images/h5/menu_iam_icon.png'),
      h5ActiveIcon: require('@/assets/images/h5/menu_iam_active_icon.png')
    },
    component: {
      render: (c) => c('router-view')
    },
    children: [
      {
        path: 'user',
        meta: {
          route: '/access/user',
          icon: '',
          requireAuth: true
        },
        component: () => import('@/views/Access/User.vue')
      },
      {
        path: 'role',
        meta: {
          route: '/access/role',
          icon: '',
          requireAuth: true
        },
        component: () => import('@/views/Access/Role.vue')
      },
      {
        path: 'dashboard-inner-user',
        meta: {
          route: '/access/dashboard-inner-user',
          icon: '',
          requireAuth: true
        },
        component: () => import('@/views/Access/InnerUser.vue')
      }
    ]
  },
  // Message Center
  {
    path: 'message-center',
    meta: {
      route: '/message-center',
      icon: require('@/assets/images/pc/menu_message_center_icon.png'),
      activeIcon: require('@/assets/images/pc/menu_message_center_active_icon.png'),
      h5Icon: require('@/assets/images/pc/menu_message_center_icon.png'),
      h5ActiveIcon: require('@/assets/images/pc/menu_message_center_active_icon.png')
    },
    component: () => import('@/views/MessageCenter/index.vue')
  },
  {
    path: 'create-message',
    meta: {
      route: '/create-message',
      hide: true,
      hideMenu: true,
      requireAuth: true
    },
    component: () => import('@/views/MessageCenter/CreateMessage.vue')
  },
  {
    path: 'message-detail/:id',
    meta: {
      route: '/message-detail',
      hide: true,
      hideMenu: true,
      requireAuth: true
    },
    component: () => import('@/views/MessageCenter/MessageDetail.vue')
  },
  {
    path: 'edit-message/:id',
    meta: {
      route: '/edit-message',
      hide: true,
      hideMenu: true,
      requireAuth: true
    },
    component: () => import('@/views/MessageCenter/EditMessage.vue')
  }
]

export default dynamicRoutes
