<template>
  <div
    class="h5-search"
    :style="{
      'margin-bottom': showTag ? '0' : '10px'
    }"
  >
    <div class="header">
      <van-field
        v-if="Object.keys(tempOptions).length && inputProps"
        v-model="tempOptions[inputProps].value"
        :placeholder="tempOptions[inputProps].label"
      >
        <template #left-icon>
          <i class="el-icon-search"></i>
        </template>
        <template #button>
          <van-button
            size="small"
            type="primary"
            @click="comfirm"
            >{{ $t('common.search') }}</van-button
          >
        </template>
      </van-field>
      <span @click="openFilter">
        <slot name="trigger"></slot>
      </span>
    </div>

    <div
      v-show="showTag && showSearchBar && Object.keys(showSearchBar).length"
      class="search-show-bar"
    >
      <van-tag
        v-for="(tag, prop) in showSearchBar"
        :key="prop"
        class="tag-bar ellipsis"
      >
        <span class="left">{{ tag.label }}:</span>
        <span class="right">{{ tag.value }}</span>
      </van-tag>
    </div>

    <van-popup
      v-model="showDialog"
      closeable
      round
      position="bottom"
      :style="{ height: '85%' }"
      safe-area-inset-bottom
      @close="closeFormDialog"
    >
      <div class="contents">
        <h2>{{ $t('search.h5Title') }}</h2>
        <div class="filter-content">
          <ul class="filter-items">
            <li
              v-for="(obj, key) in tempOptions"
              :key="key"
            >
              <h3>{{ obj.label }}</h3>
              <div
                v-if="obj.type == 'datetime'"
                class="item"
              >
                <div
                  class="date"
                  @click="opeDatePicker(obj.type, obj.value, key)"
                >
                  {{ obj.value }}
                </div>
                <span>-</span>
                <div class="date">{{ $t('common.now') }}</div>
              </div>
              <div
                v-if="obj.type == 'datetimerange'"
                class="item"
              >
                <div
                  class="date"
                  @click="opeDatePicker(obj.type, obj.value, key, 0)"
                >
                  {{ obj.value[0] }}
                </div>
                <span>-</span>
                <div
                  class="date"
                  @click="opeDatePicker(obj.type, obj.value, key, 1)"
                >
                  {{ obj.value[1] }}
                </div>
              </div>
              <div
                v-else-if="obj.type == 'daterange'"
                class="item"
              >
                <div
                  class="date"
                  @click="opeDatePicker(obj.type, obj.value, key, 0)"
                >
                  {{ obj.value[0] }}
                </div>
                <span>-</span>
                <div
                  class="date"
                  @click="opeDatePicker(obj.type, obj.value, key, 1)"
                >
                  {{ obj.value[1] }}
                </div>
              </div>
              <div
                v-else-if="obj.type == 'input'"
                class="item"
              >
                <van-field
                  v-model="obj.value"
                  :placeholder="$t('search.inputPlaceholder')"
                />
              </div>
              <ul
                v-else-if="obj.type == 'select'"
                class="item"
              >
                <li
                  v-for="(label, keys) in obj.options"
                  :key="keys"
                  class="select-item"
                  :class="obj.value == keys ? 'select-item-active' : ''"
                  @click="changeSelect(key, keys)"
                >
                  {{ label }}
                </li>
              </ul>
            </li>
          </ul>
          <div class="btns">
            <div
              class="reset"
              @click="reset"
            >
              {{ $t('common.reset') }}
            </div>
            <div
              class="confirm"
              @click="comfirm"
            >
              {{ $t('common.confirm') }}
            </div>
          </div>
        </div>
      </div>
    </van-popup>
    <van-popup
      v-model="showDatePicker"
      position="bottom"
      :style="{ height: '50%' }"
      safe-area-inset-bottom
    >
      <van-datetime-picker
        v-model="currentDate"
        :min-date="min"
        :max-date="max"
        :type="pickerType"
        :confirm-button-text="$t('common.confirm')"
        :cancel-button-text="$t('common.cancel')"
        @confirm="dateOnConfirm"
        @cancel="showDatePicker = false"
      />
    </van-popup>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import dayJs from 'dayjs'

export default {
  name: 'TheH5Search',
  props: {
    options: {
      type: Array,
      default() {
        return [
          // {
          //     prop: '', // key
          //     label: '',
          //     type: '',
          //     value: '', // default value
          //     options: {} // for seletor
          // }
        ]
      }
    },
    inputProps: {
      type: String,
      default: ''
    },
    showTag: {
      type: Boolean,
      default: true
    },
    minDate: {
      type: Date,
      default: undefined
    },
    maxDate: {
      type: Date,
      default: undefined
    },
    tableLoading: {
      type: Boolean,
      default: true
    },
    immediate: {
      //Load list now
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      tempOptions: {},
      valueObj: {},
      showDialog: false,
      dateSelection: null,
      currentDate: null,
      showDatePicker: false,
      pickerTypeTemp: 'daterange',
      optionsKey: null
    }
  },
  computed: {
    ...mapGetters('date', ['getDay']),

    pickerType() {
      let type = ''
      switch (this.pickerTypeTemp) {
        case 'datetime':
          type = 'datetime'
          break
        case 'daterange':
          type = 'date'
          break
        case 'datetimerange':
          type = 'datetime'
          break
        default:
          type = 'date'
      }
      return type
    },

    showSearchBar() {
      const { valueObj, tempOptions } = this
      let obj = {}
      Object.keys(valueObj).forEach((key) => {
        if (valueObj[key] && valueObj[key].length) {
          const tempItem = tempOptions[key]
          let el = {
            label: tempItem.label,
            value: this.formatValue(tempItem.type, valueObj[key], tempItem.options),
            type: tempItem.type
          }
          obj[key] = el
        }
      })
      return obj
    },

    max() {
      const { maxDate, getDay } = this
      return maxDate ? maxDate : new Date(getDay().valueOf())
    },

    min() {
      const { minDate, max } = this
      let t = new Date(max)
      t.setMonth(t.getMonth() - 5)
      return minDate ? minDate : t
    }
  },
  watch: {
    options: {
      handler(newValue) {
        if (newValue && newValue.length) {
          this.tempOptions = this.setEditObj(newValue)
          this.setSearchObj()
          this.dataReset() // Get list options change : The contents of options are obtained according to API
        }
      },
      deep: true
    }
  },
  created() {
    this.init()
  },
  methods: {
    init() {
      this.tempOptions = this.setEditObj(this.options)
      this.setSearchObj()
      if (this.immediate) {
        // Get list now
        this.dataReset()
      }
    },
    openFilter() {
      this.showDialog = true
    },

    setEditObj(list) {
      let obj = {}
      list.forEach((item) => {
        const { label, prop, type, value, options } = item
        obj[prop] = {
          type,
          label,
          value,
          options
        }
      })
      return obj
    },

    setSearchObj() {
      const { tempOptions } = this
      let obj = {}
      Object.keys(tempOptions).forEach((key) => {
        let { value, type } = tempOptions[key]
        if (value) {
          obj[key] = value
        } else {
          switch (type) {
            case 'daterange':
              obj[key] = []
              break
            case 'datetimerange':
              obj[key] = []
              break
            default:
              obj[key] = ''
          }
        }
      })
      this.valueObj = obj
    },

    opeDatePicker(type, value, key, valueIndex) {
      this.pickerTypeTemp = type
      this.showDatePicker = true
      setTimeout(() => {
        // HH:mm:ss Cannot initialize
        let valueTemp = null
        if (valueIndex === undefined) {
          valueTemp = value
        } else {
          valueTemp = value[valueIndex]
        }
        this.currentDate = new Date(dayJs(valueTemp).format('YYYY/MM/DD HH:mm:ss'))
      }, 10)

      this.optionsKey = key
      this.dateSelection = valueIndex // Modify the subscript of time type data
    },

    dateOnConfirm(time) {
      const { tempOptions, dateSelection, optionsKey, pickerTypeTemp } = this
      let newValue = tempOptions[optionsKey].value
      const format =
        pickerTypeTemp == 'datetime' || pickerTypeTemp == 'datetimerange'
          ? 'YYYY-MM-DD HH:mm:ss'
          : 'YYYY-MM-DD'
      if (dateSelection === undefined) {
        newValue = dayJs(time).format(format)
      } else {
        newValue[dateSelection] = dayJs(time).format(format)
      }
      this.$set(tempOptions, optionsKey, {
        ...tempOptions[optionsKey],
        value: newValue
      })
      this.showDatePicker = false
    },

    changeSelect(key, keys) {
      let newValue = this.tempOptions[key].value == keys ? '' : keys
      this.$set(this.tempOptions, key, {
        ...this.tempOptions[key],
        value: newValue
      })
    },

    reset() {
      this.tempOptions = this.setEditObj(this.options)
      this.setSearchObj()
      setTimeout(() => {
        this.dataReset('search')
        this.closeFormDialog()
      }, 300)
    },

    comfirm() {
      this.setSearchObj()
      this.dataReset('search')
      this.closeFormDialog()
    },

    closeFormDialog() {
      const { tempOptions } = this
      Object.keys(tempOptions).forEach((key) => {
        if (tempOptions[key].value && !this.valueObj[key]) {
          tempOptions[key].value = ''
        }
      })
      this.showDialog = false
    },

    formatValue(type, value, options) {
      let temp = value
      switch (type) {
        case 'daterange':
          if (value && value.length) {
            temp = value[0] + ' - ' + value[1]
          }
          break
        case 'datetimerange':
          if (value && value.length) {
            temp = value[0] + ' - ' + value[1]
          }
          break
        case 'datetime':
          if (value && value.length) {
            temp = value + ' - ' + this.$t('common.now')
          }
          break
        case 'select':
          if (value && value.length) {
            temp = options[value]
          }
          break
        default:
      }
      return temp
    },

    handleClose(prop) {
      const { showSearchBar, valueObj } = this
      delete showSearchBar[prop]
      this.$forceUpdate()
      valueObj[prop] = Array.isArray(valueObj[prop]) ? [] : ''
      this.dataReset('search')
    },

    dataReset(loadType = 'default') {
      // default/search/loadmore
      this.$emit('getList', this.valueObj, loadType)
    }
  }
}
</script>

<style lang="scss" scoped>
.h5-search {
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: nowrap;
    ::v-deep .van-field {
      border: none;
      flex-grow: 1;
      height: 34px;
      padding: 0 0 0 20px !important;
      &::after {
        content: none;
      }
      input {
        font-size: 12px;
      }
    }
    & > span {
      padding-left: 10px;
      text-align: right;
    }
  }
  .search-show-bar {
    margin-top: 12px;
    display: flex;
    flex-wrap: wrap;
    .tag-bar {
      background: rgba(45, 74, 183, 0.05);
      height: 24px;
      line-height: 24px;
      padding: 0 10px;
      margin-right: 10px;
      margin-bottom: 10px;
      border: none;
      border-radius: 4px;
      opacity: 0.85;
      .left {
        color: $h5ThemeFontColor;
      }
      .right {
        color: $h5ThemeFontColor;
        padding-left: 3px;
      }
    }
  }

  .van-popup {
    .contents {
      height: 100%;
      h2 {
        height: 22px;
        padding: 16px 0;
        text-align: center;
        font-size: 16px;
        font-weight: 400;
        color: $h5ThemeFontColor;
      }
      .filter-content {
        height: calc(100% - 54px);
        .btns {
          div {
            height: 45px;
            line-height: 45px;
            text-align: center;
            width: 50%;
            font-size: 17px;
            float: left;
          }
          .reset {
            background-color: #eaedf8;
            color: $h5ThemeColor;
          }
          .confirm {
            background-color: $h5ThemeColor;
            color: #fff;
          }
        }
        .filter-items {
          height: calc(100% - 45px);
          padding: 10px 20px;
          box-sizing: border-box;
          overflow-y: auto;
          li {
            color: $h5ThemeFontColor;
            h3 {
              font-weight: 700;
              font-size: 14px;
              padding: 10px 0;
            }
            .item {
              display: flex;
              align-items: center;
              flex-wrap: wrap;
              & > * {
                margin-right: 12px;
                margin-bottom: 18px;
                height: 34px;
                line-height: 34px;
                border-radius: 6px;
                font-size: 12px;
              }
              .date {
                color: $h5ThemeColor;
                border: 1px solid $h5ThemeColor;
                background: #eaedf8;
                padding: 0 12px;
                box-sizing: border-box;
              }
              .van-field {
                width: 100%;
                border: none;
                background-color: #f7f7f7 !important;
              }
              .select-item {
                min-width: 80px;
                text-align: center;
                padding: 0 15px;
                box-sizing: border-box;
                border: 1px solid #f7f7f7;
                background-color: #f7f7f7;
              }
              .select-item-active {
                border: 1px solid $h5ThemeColor;
                background-color: rgba(45, 74, 183, 0.1);
              }
            }
          }
        }
      }
    }
  }
}
</style>
