<template>
  <el-dialog
    v-bind="$attrs"
    :width="realWidth"
    :title="title"
    :visible="visible"
    class="center-dialog"
    top="0"
    custom-class="no-margin"
    v-on="$listeners"
  >
    <template v-for="(value, name) in $scopedSlots">
      <template v-if="name === 'default'">
        <div
          v-if="visible"
          :key="name"
          class="content"
          :style="{
            'max-height': $scopedSlots.footer ? 'calc(100vh - 190px)' : 'calc(100vh - 150px)',
            'margin-bottom': $scopedSlots.footer ? '8px' : 0
          }"
        >
          <slot :name="name"></slot>
        </div>
      </template>
      <slot
        v-else
        :name="name"
      ></slot>
    </template>
  </el-dialog>
</template>

<script>
export default {
  props: {
    minWidth: {
      type: Number,
      default: undefined
    },
    width: {
      type: String,
      default: undefined
    },
    title: {
      type: String,
      default: undefined
    },
    visible: Boolean
  },
  data() {
    return {
      maxWidthRate: 0.85,
      minWidthRate: 0.5
    }
  },
  computed: {
    realWidth() {
      const windowWidth = document.body.clientWidth
      const max = this.maxWidthRate * windowWidth
      const min = this.minWidthRate * windowWidth
      return this.width || Math.min(Math.max(this.minWidth, min), max) + 'px'
    }
  }
}
</script>

<style lang="scss" scoped>
.center-dialog {
  display: flex;
  align-items: center;
  justify-content: center;
  ::v-deep .el-dialog__header {
    font-weight: bold;
    border-bottom: 1px solid #e3e3e3;
  }
  ::v-deep .el-dialog {
    margin: 0;
    .el-dialog__body {
      padding: 30px 12px;
    }
  }
}
.content {
  overflow-y: auto;
  overflow-x: hidden;
}

.is-pc {
  .center-dialog {
    padding: 0 16px;
  }
  .content {
    padding: 0 8px;
    @include pc-scrollbar();
  }
}

.is-mobile {
  .center-dialog {
    padding: 0 12px;
  }
  .content {
    padding: 0 8px;
  }
}
</style>
