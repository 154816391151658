import {
  WO_TYPE_TRANSACTIONS,
  WO_TYPE_FINANCE,
  WO_TYPE_BALANCE,
  WO_TYPE_OTHERS,
  WO_STATUS_PENDING,
  WO_STATUS_PROCESSING,
  WO_STATUS_SOLVED,
  WO_STATUS_CLOSED
} from '@/utils/constants'
export default {
  namespaced: true,
  state: {},
  getters: {
    caseTypes(state, getters, rootState) {
      const { $t } = rootState.vm
      return {
        [WO_TYPE_TRANSACTIONS]: $t('case.types.transactions'),
        [WO_TYPE_FINANCE]: $t('case.types.finance'),
        [WO_TYPE_BALANCE]: $t('case.types.balance'),
        [WO_TYPE_OTHERS]: $t('case.types.others')
      }
    },
    caseStatus(state, getters, rootState) {
      const { $t } = rootState.vm
      return {
        [WO_STATUS_PENDING]: {
          label: $t('case.status.pending'),
          icon: 'el-icon-s-order'
        },
        [WO_STATUS_PROCESSING]: {
          label: $t('case.status.processing'),
          icon: 'el-icon-s-comment'
        },
        [WO_STATUS_SOLVED]: {
          label: $t('case.status.solved'),
          icon: 'el-icon-s-claim'
        },
        [WO_STATUS_CLOSED]: {
          label: $t('case.status.closed'),
          icon: 'el-icon-s-release'
        }
      }
    }
  },
  mutations: {},
  actions: {}
}
