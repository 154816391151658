import store from '@/store'

export const formatter = {}

formatter.suffix = function (suffix) {
  return (row, column, cellValue) => {
    return typeof suffix === 'function'
      ? suffix(cellValue)
      : cellValue === null || cellValue === undefined || cellValue === ''
      ? ''
      : cellValue + suffix
  }
}

formatter.default = function (defaultValue) {
  return (row, column, cellValue) => {
    return cellValue === null || cellValue === undefined || cellValue === ''
      ? defaultValue
      : cellValue
  }
}

formatter.withTypes = function (types, defaultValue) {
  return (row, column, cellValue) => {
    const tar = (types || []).find((t) => t.value === cellValue)
    return tar ? tar.label : defaultValue === undefined ? cellValue : defaultValue
  }
}

formatter.withTypesAndT = function (types, defaultValue) {
  return formatter.pipe(formatter.withTypes(types, defaultValue), formatter.t)
}

formatter.utc2User = (row, column, cellValue) => {
  return store.getters['date/utc2User'](cellValue)
}

formatter.t = (row, column, cellValue) => {
  return store.state.vm.$t(cellValue)
}

formatter.pipe = (...args) => {
  return (row, column, cellValue, index) => {
    return [...args].reduce((val, fn) => {
      return typeof fn === 'function' ? fn(row, column, val, index) : val
    }, cellValue)
  }
}

formatter.number = (r, c, value) => {
  if (!isNaN(parseFloat(value)) && !isNaN(Number(value))) {
    const [integer, decimal = ''] = (value + '').split('.')
    return (+integer).toLocaleString() + (decimal ? `.${decimal}` : '')
  }
  return value
}

formatter.handleNull = formatter.default('- -')
