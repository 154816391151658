import router, { DynamicRoutes } from '@/router'
import dynamicRoutes from '@/router/dynamicRoutes'
import tools from '@/utils'
import { getUserInfo } from '@/api'
import { recursionRouter, setDefaultRoute } from '@/utils/recursion-router'
import {
  SET_PERMISSION,
  CLEAR_PERMISSION,
  SET_MENU,
  CLEAR_MENU,
  SET_USER,
  CURRENT_MENU,
  SET_DEFAULT_MENU,
  CLEAR_DEFAULT_MENU,
  SET_BTNS
} from '@/utils/constants'
// import { dialogEventType } from '@/components/ConfirmBox/const.js'

export default {
  namespaced: true,
  state: {
    permissionList: null,

    // asidebar menu
    sidebarMenu: [],

    // all btns
    btns: [],

    // menu hightlight
    get currentMenu() {
      return sessionStorage.getItem(CURRENT_MENU)
    },
    set currentMenu(val) {
      if (val) {
        sessionStorage.setItem(CURRENT_MENU, val)
      } else {
        sessionStorage.removeItem(CURRENT_MENU)
      }
    }
  },
  getters: {
    stateOptions(state, getters, rootState) {
      const { $t } = rootState.vm
      return {
        1: $t('common.enable'),
        0: $t('common.disable'),
        2: $t('common.locked')
      }
    },

    h5StateOptions(state, getters) {
      const { stateOptions } = getters
      let temp = []
      Object.keys(stateOptions).forEach((item) => {
        temp.push({
          name: stateOptions[item],
          value: item
        })
      })
      return temp
    },

    cascaderOptions(state, getters, rootState) {
      const { $t } = rootState.vm
      const { permissionList } = state
      if (permissionList) {
        let temp = JSON.parse(JSON.stringify(permissionList))
        getCascader($t, temp)
        tools.removeEmptyArray(temp)
        return temp
      }
      return []
    }
  },
  mutations: {
    [SET_PERMISSION](state, routes) {
      state.permissionList = routes
    },

    [CLEAR_PERMISSION](state) {
      state.permissionList = null
    },

    [SET_MENU](state, menu) {
      state.sidebarMenu = menu
    },

    [CLEAR_MENU](state) {
      state.sidebarMenu = []
    },

    [SET_DEFAULT_MENU](state, val) {
      state.currentMenu = val
    },

    [CLEAR_DEFAULT_MENU](state) {
      state.currentMenu = null
    },

    [SET_BTNS](state, val) {
      state.btns = val
    }
  },
  actions: {
    async fetchPermission({ commit, state }) {
      let permission = []
      let temp = DynamicRoutes()

      // get user info
      let [err, res] = await tools.to(getUserInfo())
      return new Promise((resolve, reject) => {
        if (res) {
          let { userInfo, menuList } = res.data
          permission = menuList || []
          commit(SET_USER, userInfo || {}, { root: true })
        } else {
          reject(err)
        }

        // Prevent changing the original dynamicRoutes
        const allRouter = tools.deepClone(dynamicRoutes)

        const permissions = recursionRouter(permission, allRouter)

        // match routes
        let routes = permissions[0]

        // match btns
        commit(SET_BTNS, permissions[1])

        // user permissionList
        commit(SET_PERMISSION, [...permission])

        let mainRoutes = temp.find((item) => item.name == 'main')
        let children = mainRoutes.children || []
        children.push(...routes)

        // create menu
        commit(SET_MENU, children)

        // set default route
        mainRoutes.children = children
        let arr = setDefaultRoute([mainRoutes])
        if (arr.length && !state.currentMenu) {
          commit(SET_DEFAULT_MENU, arr[0])
        }

        // init route
        temp.forEach((item) => {
          router.addRoute(item)
        })
        resolve()
      })
    }
  }
}

function getCascader($t, tree) {
  tree.forEach((item) => {
    let { name, child } = item
    item.label = $t('menus.' + name)
    if (Array.isArray(child) && child.length) {
      getCascader($t, child)
    }
  })
  return
}
