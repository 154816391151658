import {
  PAYOUT_IN_PROGRESS,
  PAYOUT_SETTLED,
  PAYOUT_FAILED,
  PAYOUT_REJECTED
} from '@/utils/constants'

export default {
  namespaced: true,
  getters: {
    paymentStatus(state, getters, rootState) {
      const { $t } = rootState.vm
      return {
        [PAYOUT_IN_PROGRESS]: $t('payment.status.inProgress'),
        [PAYOUT_SETTLED]: $t('payment.status.completed'),
        [PAYOUT_REJECTED]: $t('payment.status.rejected'),
        [PAYOUT_FAILED]: $t('payment.status.failed')
      }
    }
  }
}
